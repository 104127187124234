import React from "react";
import Layout from "../components/PrivateLayout";
import AppRoutes from "../routes/appRoutes";
import styled from "styled-components";


const RootContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background: white;
`;

export default function Main() {
  return (
    <>
      <Layout>
        <RootContainer>
          <AppRoutes />
        </RootContainer>
      </Layout>

    </>
  );
}
