import { Grid } from "@mui/material";
import ServiceHeader from "./components/ServiceHeader";
import ServiceBox from "./components/ServiceBox";
import TableCell from "@mui/material/TableCell";

export default function Services() {
  return (
    <>
      <ServiceHeader />
      <div style={{ marginLeft: "1em" }}>
        <Grid container gap={"45px"} style={{ margin: "15px" }}>
          <ServiceBox />
          <ServiceBox />
          <ServiceBox />
          <ServiceBox />
          <div style={{ display: "none" }}>
            <TableCell align="left"></TableCell>
          </div>
          {/* <Grid item lg={3} md={6} xs={10}>
          <Card
            sx={{
              p: 2,
              textAlign: "center",
              borderRadius: "16px",
              cursor: "pointer",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <img
              src={suv}
              alt="suv"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Create Listing
            </Typography>
          </Card>
        </Grid> */}
        </Grid>
      </div>
    </>
  );
}
