import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useMutation, useQuery } from "react-query";
import { createShop, updateShop } from "api/userApi.js";

import Grid from "@mui/material/Grid";
import { Label } from "@mui/icons-material";

import UserContext from "context/users";

import styled from "styled-components";
import Input from "components/GenericComponents/Input/index";
import Button from "components/GenericComponents/Button/index";

const validationSchema = Yup.object({
  shopName: Yup.string().required("Shop Name is required"),
});

const ButtonContainer = styled.div`
text-align: center;
 }
`;

export default function Index({
  data = null,
  onSubmit,
  disabled = false,
  btnText,
  handleDisabled,
  type = "add",
}) {
  
  let {
    setPropertyName,
    setEditableProperties,
    setCurrentSelectedShop,
    changeIndex,
  } = React.useContext(UserContext);

  const handleButtonDisabled = (value) => {
    disabled=true
    handleDisabled(value);
  };
  
  const initialValues = {
    shopName: data?.shopName || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateShopMutate(
          { ...values, shopId: data?._id },
          {
            onSuccess: (data) => {
              setEditableProperties(data?.updatedShop);
              setCurrentSelectedShop(data?.updatedShop);
              onSubmit();
            },
          }
        );
      } else {
        createShopMutate(
          { ...values },
          {
            onSuccess: (data) => {
              setPropertyName("shop");
              changeIndex();
              setEditableProperties(data.newShop);
              setCurrentSelectedShop(data.newShop);

              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });

  const { mutate: createShopMutate } = useMutation(createShop);
  const { mutate: updateShopMutate } = useMutation(updateShop);

  const handleCheckBoxChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <Input
              label="Shop Name: "
              required
              name="shopName"
              onChange={formik.handleChange}
              value={formik.values.shopName}
              error={
                formik.touched.shopName && formik.errors.shopName
                  ? formik.errors.shopName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Shop"}
                type="submit"
                display={disabled ? "none" : ""}
                borderColor="#42B94C"
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
