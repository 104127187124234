import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import CreateIcon from "@mui/icons-material/Create";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditinspectionFrom from "../InspectionForm/index";
import EditSectionForm from "../InspectionForm/addInspectionSection";
import EditCategoryForm from "../InspectionForm/addSectionCategory";
import EditItemFrom from "../InspectionForm/addCategoryItem";
import ToolTip from "components/GenericComponents/ToolTip/index";
import InspectionContext from "context/inspection";
import ConfirmationBox from "../confirmationBox/index";
import { archiveRecord } from "api/api";

const Main = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
  border: groove;
  position: relative;
  width: 100%;
  overflow: auto;
  background: white;
  height: 80vh;
`;
const Header = styled.div`
  height: 27px;
`;
const Name = styled.span`
  color: black;
  margin-left: 12px;
`;
const EditIconContainer = styled.div`
  float: right;
  margin-right: 5px;
`;
export default function Index({ inspectionDataRefetch }) {
  const [disabled, setIsDisabled] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);
  let {
    editableProperties,
    propertyName,
    changeIndex,
    setFirstEditableProperty,
  } = React.useContext(InspectionContext);
  const [editableName, setEditableName] = React.useState(() =>
    propertyName === "inspection"
      ? editableProperties?.inspectionName
      : propertyName === "Section"
      ? editableProperties?.sectionName
      : propertyName === "Category"
      ? editableProperties?.categoryName
      : propertyName === "Item"
      ? editableProperties?.itemName
      : ""
  );
  React.useEffect(() => {
    setEditableName(
      propertyName === "inspection"
        ? editableProperties?.inspectionName
        : propertyName === "Section"
        ? editableProperties?.sectionName
        : propertyName === "Category"
        ? editableProperties?.categoryName
        : propertyName === "Item"
        ? editableProperties?.itemName
        : editableProperties?.inspectionName
    );
  }, [editableProperties]);
  const handleDisabled = (value) => {
    setIsDisabled(value);
  };
  const archiveRecordIns = () => {
    archiveRecord(editableProperties?._id).then((res) => {
      setFirstEditableProperty();
      changeIndex();
      inspectionDataRefetch();
    });
  };
  return (
    <>
      <ConfirmationBox
        openById={deleteModal}
        onclose={() => setDeleteModal(false)}
        archiveRecord={archiveRecordIns}
        title={"Are you sure you want to Archive this Inspection?"}
      />
      <Main>
        <Header>
          <Name>{editableName ? editableName : ""}</Name>
          {editableProperties ? (
            <EditIconContainer>
              <ToolTip title={`Archive this ${propertyName}`}>
                <ArchiveIcon onClick={() => setDeleteModal(true)} />
              </ToolTip>
              <ToolTip title={`Update this ${propertyName}`}>
                <CreateIcon onClick={() => handleDisabled(false)} />
              </ToolTip>
            </EditIconContainer>
          ) : (
            ""
          )}
        </Header>
        <Divider />
        {propertyName === "inspection" && editableProperties ? (
          <EditinspectionFrom
            data={editableProperties}
            disabled={disabled}
            btnText={"Update Inspection"}
            handleDisabled={handleDisabled}
            type="edit"
            onSubmit={() => {
              inspectionDataRefetch();
              handleDisabled(true);
            }}
          />
        ) : propertyName === "Section" && editableProperties ? (
          <EditSectionForm
            data={editableProperties}
            disabled={disabled}
            btnText={"Update Section"}
            handleDisabled={handleDisabled}
            type="edit"
            onSubmit={() => {
              inspectionDataRefetch();
              handleDisabled(true);
            }}
          />
        ) : propertyName === "Category" && editableProperties ? (
          <EditCategoryForm
            data={editableProperties}
            disabled={disabled}
            btnText={"Update Item"}
            handleDisabled={handleDisabled}
            type="edit"
            onSubmit={() => {
              inspectionDataRefetch();
              handleDisabled(true);
            }}
          />
        ) : propertyName === "item" && editableProperties ? (
          <EditItemFrom
            data={editableProperties}
            disabled={disabled}
            btnText={"Update Issue"}
            handleDisabled={handleDisabled}
            type="edit"
            onSubmit={() => {
              inspectionDataRefetch();
              handleDisabled(true);
            }}
          />
        ) : null}
      </Main>
    </>
  );
}
