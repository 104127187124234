import axios from "axios";
import { instance, multiPartInstance, publicInstance, publicFileDownload } from "./axiosConfig";

/*
 Authentication API FUNCTIONS
*/
export async function login(_data) {
  let { data } = await instance.post("/auth/login", _data);
  return data;
}
export async function logout(_data) {
  let { data } = await instance.post("/auth/logout", _data);
  return data;
}

/*
 Vehicle API FUNCTIONS
*/
export async function getGarageModels(userId) {
  console.log("getting garage models for user: " + userId);
  let { data } = await instance.get(
    `/vehicle/getGarageModels?userId=${userId}`
  );

  return data;
}
export async function addFavorite(userId, vin) {
  console.log("adding favorite: userId: %s, vin: %s", userId, vin);
  let { data } = await instance.put(`/vehicle/addFavorite`, {
    userId: userId,
    vin: vin,
  });

  return data;
}
export async function removeFavorite(userId, vin) {
  console.log("removing favorite: " + userId, vin);
  let { data } = await instance.put(`/vehicle/removeFavorite`, {
    userId: userId,
    vin: vin,
  });

  return data;
}

export async function getFavorites(userId) {
  console.log("getting favorites for user: " + userId);
  let { data } = await instance.get(`/vehicle/getFavorites?userId=${userId}`);

  return data;
}
export async function getFavoritesListings(userId) {
  console.log("getting favorite listings for user: " + userId);
  let { data } = await instance.get(
    `/vehicle/getFavoritesListings?userId=${userId}`
  );

  return data;
}

/*
 Listing API FUNCTIONS
*/
export async function getListingInspection(params) {
  let urlParams = new URLSearchParams(params).toString();
  let { data } = await publicInstance.get(
    `/listing/getListingInspection?${urlParams}`
  );

  return data;
}

export async function createListing(listing){
  console.log("createListing: " + JSON.stringify(listing));
   let { data } = await instance.post(`/listing/createListing`, listing);
   return data;
}
export async function updateListing(listing){
  console.log("updateListing: " + JSON.stringify(listing));
   let { data } = await instance.put(`/listing/updateListing`, listing?.listing);
   return data;
}

/* 
 Offer API FUNCTIONS
*/
/*
  params: userId, buyerUserId, vin, isActive, pageNumber, skip (skip number of pages)
*/
export async function getOffers(params) {
  let urlParams = new URLSearchParams(params).toString();
  let { data } = await publicInstance.get(`/listing/getOffers?${urlParams}`);

  return data;
}

export async function createOffer(dataone) {
  let accessToken = localStorage.getItem("access_token");

  try {
    const response = await axios.post(
      "https://portal.checkdrv.com/api/v1.4/listing/createOffer",
      dataone,
      {
        headers: {
          "Content-Type": "application/json",
          "cache-control": "no-cache",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// listingFilters - { vin, jobStatus, make, model, year, dateFrom, dateTo, inspectionType, mileageFrom, mileageTo, priceFrom, priceTo }
// /listing/getListingInspections?listingFilters={ "mileageTo":1500000, "priceTo":1000000 }
export async function getListingInspections(filters, isActive) {
  console.log("Filters for getListingInspections2: " + JSON.stringify(filters) + " isActive: " + isActive);
  let { data } = await publicInstance.get(
    `/listing/getListingInspections?listingFilters=${JSON.stringify(filters)}&isActive=${isActive}`
  );
  return data;
}
export async function getMylistings(userId, filters, isActive) {
  console.log("getting listings for user: " + userId);
  let { data } = await instance.get(
    `/listing/getListingInspections?userId=${userId}&listingFilters=${JSON.stringify(
      filters
    )}&isActive=${isActive}`
  );

  return data;
}

/*
MEDIA API FUNCTIONS
*/
export async function downloadZipImages(
  vin,
  mediaType = "photo",
  listingSection = "",
  justFeaturedPhotos = true
) {
  let { data } = await publicFileDownload.get(
    `/media/downloadZipImages?&vin=${vin}&mediaType=${mediaType}&salesListingSection=${listingSection}&justFeaturedPhotos=${justFeaturedPhotos}`,
    { responseType: "arraybuffer" }
  );
  return data;
}

export async function uploadInspectionMedia(_formData) {
  console.log("uploading media")
  for (var pair of _formData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }
  let { data } = await multiPartInstance.post("/media/uploadMedia?mediaType=photo", _formData)
  return data;
}

/*
 Vehicle API FUNCTIONS
STOCK MAKE API FUNCTIONS
*/
//create vehicle 
export async function getStockMakeImagesData() {
  let { data } = await instance.get("/vehicle/getStockMakeImagesData");
  return data;
}

export async function createStockMakeImageData(_data) {
  console.log("createStockMakeImageData data: " + JSON.stringify(_data))
  let { data } = await instance.post("/vehicle/createStockMakeImageData", _data);
  return data; 
}

export async function putStockMakeImage(_data) {
  let { data } = await instance.put(`/vehicle/putStockMakeImage/${_data.stockMakeId}`, _data ); 
  return data;
}

export async function getGarageVehicle(garageVehicleInfoId) {
  let { data } = await publicInstance.get(
    `/vehicle/getGarageVehicle?${garageVehicleInfoId}`
  );

  return data;
}

export async function putGarageVehicle(garageVehicleObject){
  console.log("putGarageVehicle: " + JSON.stringify(garageVehicleObject));
   let { data } = await instance.put(`/vehicle/putGarageVehicle`, garageVehicleObject);
   return data;
}

/*
Inspection Definition Functions
*/
export async function createInspection(_data) {
  let { data } = await instance.post("/inspectionDefinition/createInspection", _data);
  return data;
}
export async function updateInspection(_data) {
  let { data } = await instance.put(
    `/inspectionDefinition/updateInspection/${_data.inspectionId}`,
    _data
  );
  return data;
}
export async function archiveRecord(inspectionId) {
  let { data } = await instance.put(
    `/inspectionDefinition/archiveInspection/${inspectionId}`
  );
  return data;
}
export async function getInspectionDefinition(inspectionName) {
  let { data } = await instance.get(`/inspectionDefinition/getInspectionDefinition?inspectionName=${inspectionName}`);

  return data;
}
export async function getInspectionDefinitions() {
  let { data } = await instance.get(`/inspectionDefinition/getInspectionDefinitions?flag=${"true"}`);

  return data;
}
export async function createSection(_data) {
  let { data } = await instance.post("/inspectionDefinition/addSection", _data);
  return data;
}
export async function updateSection(_data) {
  let { data } = await instance.put(
    `/inspectionDefinition/updateSection/${_data.sectionId}`,
    _data
  );
  return data;
}
export async function archiveSection(sectionId) {
  let { data } = await instance.put(`/inspectionDefinition/archiveSection/${sectionId}`);
  return data;
}
export async function changeOrder(_data) {
  let { data } = await instance.put(`/inspectionDefinition/changeOrder`, _data);
  return data;
}
export async function createCategory(_data) {
  let { data } = await instance.post("/inspectionDefinition/addCategory", _data);
  return data;
}
export async function updateCategory(_data) {
  let { data } = await instance.put(
    `/inspectionDefinition/updateCategory/${_data.categoryId}`,
    _data
  );
  return data;
}
export async function archiveCategory(categoryId) {
  let { data } = await instance.put(
    `/inspectionDefinition/archiveCategory/${categoryId}`
  );
  return data;
}
export async function createItem(_data) {
  let { data } = await instance.post("/inspectionDefinition/addItem", _data);
  return data;
}
export async function updateItem(_data) {
  let { data } = await instance.put(
    `/inspectionDefinition/updateItem/${_data.itemId}`,
    _data
  );
  return data;
}
export async function archiveItem(itemId) {
  let { data } = await instance.put(`/inspectionDefinition/archiveItem/${itemId}`);
  return data;
}
export async function deleteSection(sectionId) {
  let { data } = await instance.put(`/inspectionDefinition/deleteSection/${sectionId}`);
  return data;
}
export async function deleteCategory(categoryId) {
  let { data } = await instance.put(`/inspectionDefinition/deleteCategory/${categoryId}`);
  return data;
}
export async function deleteItem(itemId) {
  let { data } = await instance.put(`/inspectionDefinition/deleteItem/${itemId}`);
  return data;
}
export async function createNewType(_data) {
  let { data } = await instance.post("/inspectionDefinition/newAppliesToType", _data);
  return data;
}
export async function getNewTypes() {
  let { data } = await instance.get("/inspectionDefinition/getAppliesToTypes");
  return data;
}
export async function unArchiveThisProperty(id, flag) {
  let { data } = await instance.put(
    `/inspectionDefinition/unArchiveThisProeperty?id=${id}&&flag=${flag}`
  );
  return data;
}
export async function getInspectionReport(inspectionId) {
  let { data } = await instance.get(`/getInspectionReport/${inspectionId}`);

  return data;
}


/*
AI Definition Functions
*/
export async function getChatGptPrompts() {
  let { data } = await instance.get(`/ai/getChatGptPrompts?flag=${"true"}`);
  return data;
}
export async function createPrompt(_data) {
  let { data } = await instance.post("/ai/createPrompt", _data);
  return data;
}
export async function updatePrompt(_data) {
  let { data } = await instance.put("/ai/updatePrompt", _data);
  return data;
}
export async function changePromptOrder(_data) {
  let { data } = await instance.put(`/ai/changeOrder`, _data);
  return data;
}
export async function deletePrompt(_data) {
  let { data } = await instance.put("/ai/deletePrompt", _data);
  return data;
}
export async function deletePromptSection(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function deletePromptCategory(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function deletePromptItem(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function createPromptSection(_data) {
  let { data } = await instance.post("/ai/createPromptSection", _data);
  return data;
}
export async function updatePromptSection(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptSection/${_data.sectionId}`,
    _data
  );
  return data;
}
export async function createPromptCategory(_data) {
  let { data } = await instance.post("/ai/createPromptCategory", _data);
  return data;
}
export async function updatePromptCategory(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptCategory/${_data.categoryId}`,
    _data
  );
  return data;
}
export async function createPromptItem(_data) {
  let { data } = await instance.post("/ai/createPromptItem", _data);
  return data;
}
export async function updatePromptItem(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptItem/${_data.itemId}`,
    _data
  );
  return data;
}
export async function archivePrompt(promptId) {
  let { data } = await instance.put(`/ai/archivePrompt/${promptId}`);
  return data;
}
export async function archivePromptSection(sectionId) {
  let { data } = await instance.put(`/ai/archivePromptSection/${sectionId}`);
  return data;
}
export async function archivePromptCategory(categoryId) {
  let { data } = await instance.put(`/ai/archivePromptCategory/${categoryId}`);
  return data;
}
export async function archivePromptItem(itemId) {
  let { data } = await instance.put(`/ai/archivePromptItem/${itemId}`);
  return data;
}