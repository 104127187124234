import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function RatingTypeRadioGroup({
  title,
  handleRadioChange,
  name,
  value = "lowMedHigh",
  disabled = false,
}) {
  const [defValue, setDefValue] = React.useState(value);
  React.useEffect(() => {
    setDefValue(value);
  }, [value]);
  const handleRadioClick = (event) => {
    handleRadioChange(name, event.target.value);
  };
  return (
    <FormControl>
      <FormLabel id="ratings-type-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="ratings-type-radio-buttons-group-label"
        name="ratings-type-radio-buttons-group"
        defaultValue={defValue}
        key={Math.random()}
      >
        <FormControlLabel
          value="lowMedHigh"
          control={<Radio />}
          label="Low-Med-High"
          onChange={handleRadioClick}
          disabled={disabled}
        />
        <FormControlLabel
          value="passFail"
          control={<Radio />}
          label="Pass-Fail"
          onChange={handleRadioClick}
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  );
}
