import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import "./OfferItem.css";


const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const formatDate = (dateString) => {
  if(dateString){
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
}

export default function OfferItem({offer}) {
  let mainImage = offer?.garageVehicleInfo?.vehicleInfo?.currentPhotoUrlString;
  let carName = offer?.garageVehicleInfo?.vehicleInfo?.vehicleType?.year + " " + offer?.garageVehicleInfo?.vehicleInfo?.vehicleType?.vehicleMake + " " + offer?.garageVehicleInfo?.vehicleInfo?.vehicleType?.vehicleModel

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/offers/1");
  };
  return (
    <TableRow
      key={offer?.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row" sx={{ display: "flex" }}>
        <div
          style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <img 
            src={mainImage ? mainImage : "/offer.png"}
            alt={`${carName}`} 
            className="offerImage"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}>
            <Typography fontSize={14}>{offer?.name}</Typography>
            <Typography color="gray" fontSize={12}>
              {carName}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell align="left">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography fontSize={14}>{offer?.date}</Typography>
          <Typography color="gray" fontSize={12}>
            {formatDate(offer?.dateOffered)}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="left">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography fontSize={14}>{offer?.expireDate}</Typography>
          <Typography color="gray" fontSize={12}>
            {formatDate(offer?.expirationDate)}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="left">{offer?.location}</TableCell>
      <TableCell
        align="left"
        sx={{ fontWeight: "bold", fontSize: "16px" }}>
        $
        {offer?.offerPrice?.toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      </TableCell>
      <TableCell align="left">
        <Button
          // disableElevation
          sx={{
            bgcolor: "#F4F4F4",
            textTransform: "capitalize",
            color: "GrayText",
          }}
          onClick={() => handleNavigate()}>
          View Offer
        </Button>
      </TableCell>
    </TableRow>
  )
}
