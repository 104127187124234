import React from "react";
import ListingsContext from "context/listings";

export default function ListingsProvider({ children }) {
  const [currentSelectedListing, setCurrentSelectedListing] =
    React.useState(null);
  const [listData, setListData] = React.useState(null);
  //const [selectedIndex, setSelectedIndex] = React.useState(0);

  return (
    <>
      <ListingsContext.Provider
        value={{
          currentSelectedListing,
          setCurrentSelectedListing,
          listData,
          setListData,
        }}
      >
        {children}
      </ListingsContext.Provider>
    </>
  );
}
