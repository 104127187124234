import React from "react";
import { useQuery } from "react-query";

//api
import { getShops, getGroups } from "api/userApi";

//Materials
import styled from "styled-components";
import Grid from "@mui/material/Grid";

//imports
import PageTitle from "components/GenericComponents/PageTitle/index";
import Button from "components/GenericComponents/Button/index";
import Modal from "components/GenericComponents/Modal/index";

//NEEDS UPDATE
import ShopForm from "../../components/Shop/ShopForm/index";

//NEEDS UPDATE
import EditShop from "../../components/Shop/EditShopProperties/index.js";
import UserContext from "context/users";

//NEEDS UPDATE
const ShopList = React.lazy(() => import("../../components/Shop/ShopList/index"));
const ShopGroupList = React.lazy(() => import("../../components/Shop/GroupList/index"));

const ShopGroups = React.lazy(() =>
  import("../../components/Shop/ShopGroups/index")
);
const ShopUsers = React.lazy(() =>
  import("../../components/Shop/ShopUsers/index")
);

const ButtonContainer = styled.div`
margin-left: 6px;
margin-bottom: 8px;
margin-top: -38px;
float: right;
}
`;

const Title = styled.div`
  text-align: center;
  font-size: larger;
`;

export default function Index() {
  const [modal, setModal] = React.useState(false);
  const [selectedShop, setSelectedShop] = React.useState(null);
  let { currentSelectedShop } = React.useContext(UserContext);
  
  const ShopData = useQuery("getShops", getShops, {});

  const GroupData = useQuery("getGroups",() => getGroups(currentSelectedShop?._id),{});

  //NEEDS UPDATE
  const handleSelectShop = (Shop) => {
    handleSelectShop(Shop);
    GroupData.refetch();
  };

  const handleSelectGroup = (Group) => {
    handleSelectGroup(Group);
  };

  return (
    <>
      <React.Suspense>
        <PageTitle title="Shop Management 2" />
        <Modal
          open={modal}
          onclose={() => {
            setModal(false);
          }}
          title={
            <>
              <span> Add Shop</span>
            </>
          }
        >
          <ShopForm
            onSubmit={() => {
              //NEEDS UPDATE
              ShopData.refetch();
              setModal(false);
            }}
          />
        </Modal>
        <ButtonContainer>
            {/* NEEDS UPDATE */}
          <Button
            children={"Add Shop"}
            borderColor="#42B94C"
            onClick={() => setModal(true)}
          />
        </ButtonContainer>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Title>Shop List</Title>
            <ShopList
              listData={ShopData?.data}
              handleSelectShop={handleSelectShop}
            />
          </Grid>
          <Grid item xs={2}>
            <Title>Group List</Title>
            <ShopGroupList
              listData={GroupData?.data}
              handleSelectGroup={handleSelectGroup}
            />
          </Grid>
          <Grid item xs={2}>
            <Title>Users</Title>
            <ShopUsers
              selectedShop={
                currentSelectedShop
                  ? currentSelectedShop
                  : ShopData.data?.[0]?.items?.[0]
              }
              ShopDataRefetch={ShopData.refetch}
            />
          </Grid>
            {/* NEEDS UPDATE */}
          <Grid item xs={5}>
            <Title>Edit Properties</Title>
            <EditShop
              name="Shop"
              ShopDataRefetch={ShopData.refetch}
            />
          </Grid>
        </Grid>
      </React.Suspense>
    </>
  );
}
