import React from "react";
import {
  Grid,
  Avatar,
  Button,
  TextField,
  IconButton,
  useMediaQuery,
} from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
// import { toast } from "react-toastify";
//import { updateProfile } from "../../Shop/auth/actions";
//import "./styles.scss";
import AuthContext from "context/autho";
import TableCell from "@mui/material/TableCell";

export default function Profile() {
  const { user } = React.useContext(AuthContext);
  const currentUser = user;
  // const navigate = useNavigate();
  //const dispatch = useDispatch();

  //const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  //const [btnLoading, setBtnLoading] = useState<boolean>(false);
  //const [uploadedImage, setUploadedImage] = useState<string>("");

  /*const cleanPhoneNumber = (phone_number: string) => {
    return phone_number
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
  };*/

  /*const formatPhoneNumber = (phone_number: string) => {
    if (!phone_number) return "";
    var x = phone_number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (x != null) {
      return (phone_number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""));
    }
    else return x;
  };*/
  const isLargeScreen = useMediaQuery("(max-width: 768px)");
  const profileSchema = Yup.object().shape({
    profileImage: Yup.mixed().required("Please upload profile image"),
    firstName: Yup.string()
      .required("Please enter legal first name")
      .matches(/\S/, "Invalid legal first name")
      .max(25, "Must be less than or equal to 25 characters"),
    lastName: Yup.string()
      .required("Please enter legal last name")
      .matches(/\S/, "Invalid legal last name")
      .max(25, "Must be less than or equal to 25 characters"),
    nickname: Yup.string()
      .matches(/\S/, "Invalid nickname")
      .max(25, "Must be less than or equal to 25 characters"),
    phone: Yup.string()
      .required("Please enter phone number")
      .min(14, "Phone must be at least 10 characters")
      .matches(/\S/, "Invalid phone")
      .nullable(),
    email: Yup.string()
      .email("Please enter valid email address")
      .required("Please enter email address")
      .matches(/\S/, "Invalid email")
      .max(60, "Must be less than or equal to 60 characters"),
    address: Yup.string()
      .required("Please enter address")
      .matches(/\S/, "Invalid address")
      .max(50, "Must be less than or equal to 50 characters"),
  });

  const formik = useFormik({
    initialValues: {
      profileImage: currentUser?.profilePic,
      firstName: currentUser?.name.split(" ")[0],
      lastName: currentUser?.lastName
        ? currentUser?.lastName
        : currentUser?.name.split(" ")[1],
      nickname: currentUser?.nickname,
      phone: currentUser?.phoneNumber,
      email: currentUser?.email,
      address: currentUser?.address,
    },
    enableReinitialize: true,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      /*
      if (cleanPhoneNumber(values.phone).length === 10) {
        setBtnLoading(true);
        dispatch(
          updateProfile({
            profileImage: values.profileImage,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            phone: values.phone,
            email: values.email,
            address: values.address,
            setBtnloading: setBtnLoading,
          })
        )
      } else {
        toast.error("Please enter a valid phone number")
      }*/
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Grid container={isLargeScreen ? false : true}>
            <Grid item lg={12} md={12} xs={12} sx={{ px: 2, py: 1, mt: 4 }}>
              <IconButton
                component="label"
                color="info"
                sx={{ position: "relative" }}>
                <Avatar
                  size="large"
                  src={formik.values.profileImage}
                  alt={user.name.toUpperCase()}
                  sx={{ width: "100px", height: "100px" }}
                />

                <input
                  type="file"
                  name="profileImage"
                  accept="image/jpeg, image/png"
                  onChange={(event) => {
                    if (event.target.files) {
                      event.preventDefault();
                      formik.setFieldValue(
                        "profileImage",
                        URL.createObjectURL(event.target.files[0])
                      );
                      // setUploadedImage(URL.createObjectURL(event.target.files[0]))

                      const file = event.target.files[0];
                      const reader = new FileReader();

                      reader.onload = (e) => {
                        const dataURL = e?.target?.result;
                        formik.setFieldValue("profileImage", dataURL);
                      };
                      reader.readAsDataURL(file);
                      //setUploadedImage(URL.createObjectURL(event.target.files[0]))
                    }
                  }}
                  hidden
                />
              </IconButton>
              {formik.touched.profileImage && formik.errors.profileImage && (
                <p className="error">
                  <>{formik.errors.profileImage}</>
                </p>
              )}
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              sx={{ px: 2, py: 2, marginBottom: "10px" }}
              className="input_fields">
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    First Name
                  </span>
                  <TextField
                    id="firstName"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    name="firstName"
                    placeholder="First Name"
                    value={formik.values && formik.values.firstName}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.firstName && formik.errors.firstName
                    )}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName ? (
                        <span className="error">
                          {formik.errors.firstName?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Last Name
                  </span>
                  <TextField
                    id="lastName"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    placeholder="Last Name"
                    name="lastName"
                    value={formik.values && formik.values.lastName}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.lastName && formik.errors.lastName
                    )}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName ? (
                        <span className="error">
                          {formik.errors.lastName?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
              </div>
              {/* <TextField
              id="nickname"
              label="Nickname"
              variant="filled"
              margin="dense"
              name="nickname"
              value={formik.values && formik.values.nickname}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.nickname && formik.errors.nickname
              )}
              helperText={ 
                formik.touched.nickname && formik.errors.nickname ? (
                <span className="error">{formik.errors.nickname?.toString()}</span>
              ) : null}
              fullWidth /> */}
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Email
                </span>
                <TextField
                  id="email"
                  sx={{ backgroundColor: "#F4F4F4" }}
                  placeholder="Email"
                  margin="dense"
                  name="email"
                  value={formik.values && formik.values.email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={
                    <span className="error">
                      {formik.errors.email?.toString()}
                    </span>
                  }
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Phone Number
                </span>
                <TextField
                  id="phone"
                  sx={{ backgroundColor: "#F4F4F4", border: "none" }}
                  margin="dense"
                  name="phone"
                  placeholder="Phone Number"
                  value={formik.values && formik.values.phone}
                  //onChange={(e: any) =>
                  //formik.setFieldValue("phone", formatPhoneNumber(e.target.value))
                  //}
                  // icon={
                  //   <span className="absolute left-2 lg:left-3 text-mobile-grey-600 lg:text-3xl">
                  //     +1
                  //   </span>
                  // }
                  error={Boolean(formik.touched.phone && formik.errors.phone)}
                  helperText={
                    formik.touched.phone && formik.errors.phone ? (
                      <span className="error">
                        {formik.errors.phone?.toString()}
                      </span>
                    ) : null
                  }
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Address
                </span>
                <TextField
                  id="address"
                  placeholder="Address"
                  sx={{ backgroundColor: "#F4F4F4" }}
                  margin="dense"
                  name="address"
                  value={formik.values && formik.values.address}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.address && formik.errors.address
                  )}
                  helperText={
                    formik.touched.address && formik.errors.address ? (
                      <span className="error">
                        {formik.errors.address?.toString()}
                      </span>
                    ) : null
                  }
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>
              <Button
                type="submit"
                // loading={btnLoading}
                sx={{
                  mt: 2,
                  float: "right",
                  backgroundColor: "#87AA8D",
                  color: "white",
                  textTransform: "capitalize",
                  padding: "7px 15px",
                  borderRadius: "6px",
                }}>
                Save Changes
              </Button>
              <Button
                // loading={btnLoading}
                sx={{
                  mt: 2,
                  float: "right",
                  backgroundColor: "#F4F4F4",
                  marginRight: 2,
                  color: "#969696",
                  textTransform: "capitalize",
                  padding: "7px 15px",
                  borderRadius: "6px",
                }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
}
