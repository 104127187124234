import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import Paper from "@mui/material/Paper";
import logo from "assets/images/front.png";
import { useNavigate } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";

import "../../App.css";

//context
import GarageContext from "context/garage";

export default function CarCard({ vehicleRecord }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //handle context

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 340, sm: 520, md: 800 },
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 1,
  };
  let { setCurrentSelectedInspection } = React.useContext(GarageContext);

  const handleSelectInspection = (vehicleRecord) => {
    setCurrentSelectedInspection(vehicleRecord);
  };

  // const divStyle = {
  //   display: "flex",
  //   alignItems: "center",
  // };

  let navigate = useNavigate();

  const handleNavigate = () => {
    handleSelectInspection(vehicleRecord);
    navigate("/availableListing/carDetails/" + extraUrlChars(vehicleRecord));
  };

  let mainImage = vehicleRecord?.vehicleInfo?.currentPhotoUrlString;

  return (
    <>
      <React.Suspense>
        <Grid item xs={12} sm={6} md={4} lg={2} className="vehiclecardinner">
          <Paper elevation={3} style={{ padding: "16px" }}>
            <Box
              onClick={handleNavigate}
              sx={{
                backgroundImage: `url(${mainImage ? mainImage : logo})`,
                height: "200px",
                marginBottom: "16px",
                position: "relative",
                backgroundSize: "contain",
                cursor: "pointer",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}></Box>

            <div className="car_name">
              <span className="car_name_title">
                {vehicleRecord?.vehicleInfo?.vehicleType?.year}{" "}
                {vehicleRecord?.vehicleInfo?.vehicleType?.vehicleMake}
              </span>
              <span className="car_garge">My Garage</span>
              <span className="car_adress">
                {vehicleRecord?.vehiclePhysicalLocation?.city},{" "}
                {vehicleRecord?.vehiclePhysicalLocation?.state}
              </span>
              <button onClick={handleOpen}>Vehicle Settings</button>
            </div>
          </Paper>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} position={"relative"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}>
              <img src="/logo.svg" style={{ width: "214px" }} alt="logo" />
            </Typography>
            <Box display={"flex"} width={"100%"}>
              <Box sx={{ flex: 1, display: { xs: "none", md: "block" } }}>
                <img
                  src="/modalimg.png"
                  style={{ objectFit: "cover" }}
                  alt="modalimg"
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4  px",
                }}>
                <Typography
                  fontSize={65}
                  lineHeight={1}
                  textAlign={"center"}
                  color={"#253528"}
                  fontWeight={"bold"}>
                  Download
                </Typography>
                <Typography
                  fontSize={50}
                  lineHeight={1}
                  textAlign={"center"}
                  color={"#253528"}
                  fontWeight={"bold"}>
                  Our App
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}>
                  <div>
                    <Link>
                      <img
                        class="bn45"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                        alt="bn45"
                      />
                    </Link>
                  </div>
                  <div>
                    <Link>
                      <img
                        class="bn46"
                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-Shop.svg"
                        alt="bn45"
                      />
                    </Link>
                  </div>
                </Box>

                <img src="/qr.png" alt="qr" />
              </Box>
            </Box>
            <Box
              position={"absolute"}
              top={10}
              left={10}
              bgcolor={"#8EAB90"}
              display={"flex"}
              alignItems={"center"}
              borderRadius={"20px"}
              p={1}
              onClick={handleClose}
              sx={{ cursor: "pointer", color: "#707070" }}>
              <Close />
            </Box>
          </Box>
        </Modal>
      </React.Suspense>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
}

function extraUrlChars(inspection) {
  let make = inspection?.vehicleInfo?.vehicleType?.vehicleMake;
  let model = inspection?.vehicleInfo?.vehicleType?.vehicleModel;
  let year = inspection?.vehicleInfo?.vehicleType?.year;
  let vinLastTen = inspection?.vehicleInfo?.vin.slice(-10);
  var extraChars = "used-" + make + "-" + model + "-" + year + "-" + vinLastTen;
  return extraChars;
}

export function get_image_urls(
  inspection = "",
  mediaType = "photoUrl",
  photoWeightedOnly = true
) {
  var ourPhotoURLs = [];

  inspection?.inspectionType?.sections.forEach((section) => {
    if (section?.$mediaType && section?.sectionName) {
      ourPhotoURLs.push([section?.photoUrl, section?.sectionName]);
    }

    section?.categories.forEach((category) => {
      if (
        category?.photoUrl &&
        category?.categoryName &&
        category?.photoWeightEnabled === "Yes"
      ) {
        ourPhotoURLs.push([category?.photoUrl, category?.categoryName]);
      }
    }); //end category
  }); //end section

  return ourPhotoURLs;
}
