import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxesGroup({
  title,
  handleCheckBoxChange,
  options = [],
  name,
  disabled,
}) {
  const [state, setState] = React.useState(() => [...options]);
  React.useEffect(() => {
    setState(options);
  }, [options]);
  const handleChange = (event, index) => {
    let temp_state = [...state];
    let temp_element = { ...temp_state[index] };
    temp_element.value = event.target.checked;
    temp_state[index] = temp_element;
    setState(() => [...temp_state]);
    handleCheckBoxChange(name, temp_state);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">{title}</FormLabel>
        <FormGroup
          sx={{ display: "flex", flexDirection: "row", border: "groove" }}
          key={Math.random()}
        >
          {options?.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={state[index]?.value}
                    onChange={(e) => handleChange(e, index)}
                    name={option?.name}
                    disabled={disabled}
                  />
                }
                label={option.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
