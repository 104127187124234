import React from "react";
import Modal from "components/GenericComponents/Modal/index";
import styled from "styled-components";

const Button = styled.button`
  padding: 0.5rem 2rem !important;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#42B94C"};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#42B94C"};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "white")};
  &:hover {
    ${(props) => (props.hoverEffect ? props.hoverEffect : "color: white;")}
  }
  border-radius: 50px;
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & * {
    margin: 2px;
  }
`;
const ButtonContainer = styled.div`
  & * {
    margin: 0 4px;
  }
  display: flex;
  justify-content: center !important;
`;

onclose = { onclose };
export default function Index({
  openById,
  onclose,
  archiveRecord,
  title,
  btnText = "Archive",
}) {
  return (
    <>
      <Modal
        open={!!openById}
        onclose={onclose}
        title={
          <Wrapper>
            <strong>{title}</strong>
          </Wrapper>
        }
        size={"md"}
        centered={true}
        showOnCloseIcon={false}
      >
        <ButtonContainer>
          <Button
            backgroundColor="#d32f2f"
            borderColor="#d32f2f"
            onClick={() => {
              archiveRecord(openById);
              onclose();
            }}
          >
            {btnText}
          </Button>
          <Button onClick={onclose}>Cancel</Button>
        </ButtonContainer>
      </Modal>
    </>
  );
}
