/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import useAuth from "../../../Hooks/useAuthHook";
import { addFavorite, removeFavorite } from "api/api";
import { useLocation } from "react-router-dom";
import { Bookmark } from "@mui/icons-material";
import { toast } from "react-toastify";

import "./style.css";

const DetailsHeader = ({ listing, from }) => {
  const [openone, setOpenone] = React.useState(false);
  const handleOpenone = () => {
    setOpenone(true);
  };

  const handleCloseone = () => {
    setOpenone(false);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  // const bookmarkedListing = useQuery("getFavourites", () => getFavoritesListings(user?._id), {});
  const [bookmark, setBookmark] = useState(location.state.bookmark);
  const handleBookMark = async () => {
    if (!isAuthenticated) {
      sessionStorage.setItem("car_id", listing?.vin);
      return navigate("/auth/login");
    } else {
      await addFavorite(user?._id, listing?.vin);
      toast.success("Car added to Favorites");
      setBookmark(true);
    }
  };

  const handleRemoveFavourites = async () => {
    if (listing?.vin) {
      await removeFavorite(user?._id, listing?.vin);
    }

    toast.warn("Car removed from Favorites");
    setBookmark(false);
  };

  return (
    <Box
      className="details__header"
      sx={
        isAuthenticated
          ? {
              width: { xs: "100%", sm: "80%", md: "80%" },
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: "auto" },
              zIndex: 1,
            }
          : { width: "100%", position: "relative" }
      }>
      <Box
        onClick={() =>
          navigate(from, { state: bookmark ? { id: listing._id } : {} })
        }
        sx={{
          bgcolor: "#F4F4F4",
          padding: { xs: "3px", md: "5px" },
          borderRadius: "8px",
          marginLeft: "5px",
          display: "flex",
          alignItems: "center",
        }}>
        <ArrowBackIosNewOutlinedIcon sx={{ color: "#87AC8D" }} />
      </Box>
      {bookmark === true ? (
        <Bookmark
          sx={{
            color: "#87AC8D",
            marginLeft: "1em",
            display: { xs: "none", md: "block" },
            cursor: "pointer",
          }}
          onClick={handleRemoveFavourites}
        />
      ) : (
        <BookmarkBorderOutlinedIcon
          sx={{
            color: "#87AC8D",
            marginLeft: "1em",
            display: { xs: "none", md: "block" },
            cursor: "pointer",
          }}
          onClick={handleBookMark}
        />
      )}

      <div className="header__text">
        <Typography
          fontSize={{ xs: "12px", sm: "14px", md: "20px" }}
          fontWeight={"bold"}
          sx={{ textAlign: "center" }}>
          {listing?.inspection?.vehicleInfo?.vehicleType?.year.toUpperCase()}{" "}
          {listing?.inspection?.vehicleInfo?.vehicleType?.vehicleMake.toUpperCase()}{" "}
          {listing?.vehicleInfo?.vehicleType?.vehicleModel.toUpperCase()}
        </Typography>
        <Typography
          fontSize={{ xs: "14px", md: "20px" }}
          sx={{ textAlign: "center" }}
          fontWeight={"bold"}>
          {listing && "$"}
          {listing?.sellerDesiredPrice
            ?.toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Typography>
      </div>
      <div className="details__btn">
        {/* <Button
          disableRipple
          sx={{
            color: "black",
            bgcolor: "#F4F4F4",
            width: { xs: "6em", md: "12em" },
            fontSize: { xs: "8px", md: "12px" },
          }}
          className="hover-button">
          <ShareOutlinedIcon sx={{ color: "#87AC8D", fontSize: "18px" }} />
          SHARE
        </Button>
        <div className="icon-container">
          <div className="icon">
            <EmailRoundedIcon className="mail-icon" />
          </div>
          <div className="icon">
            <FacebookRoundedIcon className="facebook-icon" />
          </div>

          <div className="icon">
            <IosShareRoundedIcon className="share-icon" />
          </div>
        </div> */}

        <div class="dropdown">
          {/* <button class="dropbtn">Dropdown</button> */}
          <Button
            disableRipple
            sx={{
              color: "black",
              bgcolor: "#F4F4F4",
              width: { xs: "6em", md: "12em" },
              fontSize: { xs: "8px", md: "12px" },
            }}
            className="dropbtn">
            <ShareOutlinedIcon sx={{ color: "#87AC8D", fontSize: "18px" }} />
            SHARE
          </Button>

          <div className="dropdown-content">
            <div className="icons-section">
              <div className="icon">
                <EmailRoundedIcon className="mail-icon" />
              </div>
              <div className="icon">
                <FacebookRoundedIcon className="facebook-icon" />
              </div>

              <div className="icon">
                <IosShareRoundedIcon className="share-icon" />
              </div>
            </div>
          </div>
        </div>

        <Button
          disableRipple
          sx={{
            ":hover": {
              bgcolor: "transparent",
              border: "1px solid #87AC8D",
              color: "#87AC8D",
            },
            color: "white",
            bgcolor: "#87AC8D",
            width: { xs: "6em", md: "12em" },
            fontSize: { xs: "8px", md: "12px" },
          }}
          onClick={handleOpenone}>
          Make Offer
        </Button>
      </div>
      <div className="checklogo">
        <img
          src="/check.png"
          alt=""
          style={{ width: "32px", marginRight: "9px" }}
        />
      </div>
    </Box>
  );
};

export default DetailsHeader;
