import React, { useEffect, useState } from "react";
import { Box, Grid, Tabs, Button } from "@mui/material";
// import SellingOffers from "./SellingOffers";
// import BuyingOffers from "./BuyingOffers";
import OffersHeader from "./components/OffersHeader";
import OfferItems from "./components/OfferItems";
import AuthContext from "context/autho";

import { getOffers } from "api/api";

export default function Offers() {
  const [value, setValue] = React.useState("1");
  const [offers, setOffers] = useState([]);
  const [buyingOffers, setBuyingOffers] = useState([]);
  const [sellingOffers, setSellingOffers] = useState([]);

  const { user } = React.useContext(AuthContext);

  const fetchAllData = async () => {
    const res = await getOffers({listingUserId:user._id});
    const res2 = await getOffers({offeringUserId:user._id});
    console.log("res___________", res);
    setOffers(res.offers);
  };

  const fetchBuyingData = async () => {
    const res = await getOffers({offeringUserId:user._id});
    console.log("res___________", res);
    setBuyingOffers(res.offers);
  };

  const fetchSellingData = async () => {
    const res = await getOffers({listingUserId:user._id});
    console.log("res___________", res);
    setSellingOffers(res.offers);
  };

  useEffect(() => {
    fetchAllData();
    fetchBuyingData();
    fetchSellingData();
  }, []);

  return (
    <>
      <OffersHeader />
      <div style={{ marginLeft: "1em", marginRight: "1rem" }}>
        <Box display={"flex"} gap={"8px"}>
          <Button
            onClick={() => setValue("1")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "1" ? "#F4FFF4" : "#F4F4F4",
              color: value === "1" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "0 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}
          >
            All
          </Button>
          <Button
            onClick={() => setValue("2")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "2" ? "#F4FFF4" : "#F4F4F4",
              color: value === "2" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "0 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}
          >
            Buying
          </Button>
          <Button
            onClick={() => setValue("3")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "3" ? "#F4FFF4" : "#F4F4F4",
              color: value === "3" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "0 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}
          >
            Selling
          </Button>
          <Button
            onClick={() => setValue("4")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "4" ? "#F4FFF4" : "#F4F4F4",
              color: value === "4" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "0 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}
          >
            Sold
          </Button>
          <Button
            onClick={() => setValue("5")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "5" ? "#F4FFF4" : "#F4F4F4",
              color: value === "5" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}
          >
            Bought
          </Button>
        </Box>

        <Grid container>
          <Grid item lg={12} md={12} xs={12} className="offerPageDesign">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} aria-label="basic tabs example"></Tabs>
              </Box>

              {value === "1" && (
                <OfferItems offers={offers}/>
              )}
              {value === "2" && (
                <OfferItems offers={buyingOffers}/>
              )}
              {value === "3" && (
                <OfferItems offers={sellingOffers}/>
              )}
            </Box>
          </Grid>
        </Grid>

      </div>
    </>
  );
}
