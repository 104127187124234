import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import RowRadioButtonsGroup from "components/GenericComponents/RadioGroup/index";
import RatingTypeRadioGroup from "components/GenericComponents/RatingTypeRadioGroup/index";
import PhotoWeightingRadioGroup from "components/GenericComponents/PhotoWeightingRadioGroup/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { useMutation } from "react-query";
import { createCategory, updateCategory } from "api/api";
import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";
import UserContext from "context/users";
import { FormatBold, StrikethroughS, WidthNormalSharp } from "@mui/icons-material";

const ButtonContainer = styled.div`
text-align: center;
 }
`;

const validationSchema = Yup.object({
  categoryName: Yup.string().required("Category Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  sectionId,
  disabled = false,
  handleDisabled,
  type = "add",
  btnText,
}) {
  let { setEditableProperties } = React.useContext(UserContext);
  const initialValues = {
    categoryName: data?.categoryName || "",
    videoRequired: data?.videoRequired || "Yes",
    videoMaxLength: data?.videoMaxLength || "20",
    photoRequired: data?.photoRequired || "Yes",
    description: data?.description || "",
    ratingType: data?.ratingType || "lowMedHigh",
    photoWeight: data?.photoWeight || 50,
    photoWeightEnabled : data?.photoWeightEnabled || "No",
    listingPhotoEnabled : data?.listingPhotoEnabled || "No",
    listingPhotoWeight: data?.listingPhotoWeight || 50,
    listingPhotoSection: data?.listingPhotoSection || "General",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateCategoryMutate(
          { ...values, categoryId: data._id },
          {
            onSuccess: (data) => {
              onSubmit();
              setEditableProperties(data?.category);
            },
          }
        );
      } else {
        createCategoryMutate(
          { ...values, sectionId },
          {
            onSuccess: () => {
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createCategoryMutate } = useMutation(createCategory);
  const { mutate: updateCategoryMutate } = useMutation(updateCategory);

  const handleRadioChange = (key, value) => {
    formik.setFieldValue(key, value);
  };
  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "15px" }}>
          <Grid item xs={12}>
            <Input
              label="Category Name"
              required
              name="categoryName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.categoryName}
              error={
                formik.touched.categoryName && formik.errors.categoryName
                  ? formik.errors.categoryName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <RowRadioButtonsGroup
              title="Video Required"
              name="videoRequired"
              onChange={formik.handleChange}
              value={formik.values.videoRequired}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <RowRadioButtonsGroup
              title="Max Video Length (Seconds)"
              name="videoMaxLength"
              onChange={formik.handleChange}
              value={formik.values.videoMaxLength}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <RowRadioButtonsGroup
              title="Photo Required"
              name="photoRequired"
              onChange={formik.handleChange}
              value={formik.values.photoRequired}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <RatingTypeRadioGroup
              title="Rating Type"
              name="ratingType"
              onChange={formik.handleChange}
              value={formik.values.ratingType}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={3}>
            <PhotoWeightingRadioGroup
              title="Use as Featured Photo"
              name="photoWeightEnabled"
              onChange={formik.handleChange}
              value={formik.values.photoWeightEnabled}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Weight (0 lowest to 100 highest)"
              name="photoWeight"
              onChange={formik.handleChange}
              value={formik.values.photoWeightEnabled == "Yes" ? formik.values.photoWeight : "disabled" }
              disabled={formik.values.photoWeightEnabled == "No"}
            />
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={3}>
            <PhotoWeightingRadioGroup
              title="Use as Listing Photo"
              name="listingPhotoEnabled"
              onChange={formik.handleChange}
              value={formik.values.listingPhotoEnabled}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Section (Spelling / Capitalization!)"
              name="listingPhotoSection"
              onChange={formik.handleChange}
              value={formik.values.listingPhotoEnabled == "Yes" ? formik.values.listingPhotoSection   : "disabled" }
              disabled={formik.values.listingPhotoEnabled == "No"}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Weight (0 lowest to 100 highest)"
              name="listingPhotoWeight"
              onChange={formik.handleChange}
              value={formik.values.listingPhotoEnabled == "Yes" ? formik.values.listingPhotoWeight : "disabled" }
              disabled={formik.values.listingPhotoEnabled == "No"}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineTextFields
              onChange={formik.handleChange}
              label={"Description"}
              name="description"
              value={formik.values.description}
              disabled={disabled}
              size="1%"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Category"}
                type="submit"
                display={disabled ? "none" : ""}
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
