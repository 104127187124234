import React from "react";
import GarageContext from "context/garage";

export default function GarageProvider({ children }) {

  const [currentSelectedVehicle, setCurrentSelectedVehicle] =
    React.useState(null);
  const [listData, setListData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  return (
    <>
      <GarageContext.Provider
        value={{
          currentSelectedVehicle,
          setCurrentSelectedVehicle,
          selectedIndex,
          setSelectedIndex,
          listData,
          setListData,
        }}
      >
        {children}
      </GarageContext.Provider>
    </>
  );
}
