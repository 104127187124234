import React from "react";

//api
import {  useQuery } from "react-query";
import { getListingInspection } from "api/api";

//functions
import ListingsContext from "context/listings";
import CarDetails from "./ListingDetails";

export default function ListingDetailsWrapper() {

  let {
    currentSelectedListing,
    setCurrentSelectedListing,
  } = React.useContext(ListingsContext);

  let path = window.location.pathname;
  let carParts = path.split("-");
  let ourParams = {};
  var vin = "";
  if(carParts.length === 5){  
    vin = carParts[4];
    ourParams = {vinPart: carParts[4], year: carParts[3], make: carParts[1], model: carParts[2] }
  }

  // const { status, error, data } = useQuery("getListingInspection"+vin, getListingInspection(ourParams));

  const { data } = useQuery(["getListingInspection"+vin], () => getListingInspection(ourParams),
  {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  });

  setCurrentSelectedListing(data?.listing);
 
  return (
    <>
      {/* {status === "loading"? <Box sx={{ width:"100%",margin:"20px",marginInline:"auto",display:'flex',justifyContent:"center"}}> <CircularProgress color="success"/> </Box>: <CarDetails listing={currentSelectedInspection}/>} */}
      <CarDetails listing={currentSelectedListing} />
    </>
  );
}

export function get_image_urls_multi_section(inspection = '', mediaType = 'photoUrl', photoWeightedOnly = true ){
	var ourPhotoURLs = {};

  inspection?.inspectionType?.sections.forEach((section)=> {
    section?.categories.forEach((category)=>{

      let photoSection = category?.listingPhotoSection;
      if(photoSection && category?.photoUrl){
        //add our values to this key
        if(ourPhotoURLs[photoSection] ){
          ourPhotoURLs[photoSection].push([category?.photoUrl,category?.categoryName,category?.photoWeight]);
        } else {
          ourPhotoURLs[photoSection] = [] ;
        }
      }
    })
  })
  
  return ourPhotoURLs;
}