import React, { useState, useEffect } from "react";
import { uploadInspectionMedia } from "api/api";

export default function Index({
  inspection,
  section,
  category,
}) {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  useEffect(() => {
    if(!selectedImageUrl){
      if(category?.photoThumbnail){
        setSelectedImageUrl(category?.photoThumbnail)
      } else {
        setSelectedImageUrl(category?.photoUrl)
      }
    }
    return;
  })

  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    setSelectedImageUrl(URL.createObjectURL(selectedImage))

    return () => {
      URL.revokeObjectURL(selectedImageUrl);
    }
  }, [selectedImage]);

  const handleChange = async (e) => {
    if (e.target.files) {
      console.log("uploading photo for category: " + category?._id + " and name: " + category?.categoryName)
      await handleUploadPhoto(inspection, section, category, e.target.files[0])
      setSelectedImage(e.target.files[0])
    }
  }

  return (
    <>
      <label for={category?._id} style={{fontSize: "12px", border: '1px solid black', marginRight: "5px"}}>Upload New</label>
      <input
        type="file"
        id={category?._id}
        name="upload-button" 
        style={{ display: 'none' }}
        onChange={ (e)=>{ handleChange(e)} }
      />
      
      { selectedImageUrl ? 
        <img
          width={"80px"}
          src={selectedImageUrl}
        />
      : null }

      { category?.categoryName }
    </>
  );
}

async function handleUploadPhoto(inspection, section, category, imageData) {
  console.log("handleUploadPhoto for category: " + category?._id + " and name: " + category?.categoryName)
  const formData = await createFormData(inspection, section, category, imageData);
  let uploadData = uploadInspectionMedia(formData)
}

async function createFormData (inspection, section, category, imageData) {
  let cli = inspection

  let formData = new FormData();
  formData.append("categoryId", category?._id);
  formData.append("inspectionId", cli?._id);
  formData.append("userId", cli?.userId);
  formData.append("fileFolder", "InspectionImages/" + inspection?._id + "/" +  section?._id + "/" + category?._id);
  formData.append("data", imageData, "image.png");
  
  return formData
}