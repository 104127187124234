import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = "https://portal.checkdrv.com/api/v1.4"
//const BASE_URL = "http://localhost:8080/api/v1.4"

var instance = axios.create({
  baseURL: BASE_URL,

  headers: {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  },
});

var multiPartInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "cache-control": "no-cache"
  },
});

var publicInstance = axios.create({
  baseURL: BASE_URL,

  headers: {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    "token": "fcQPP1X0MtA6wuhg8ivQTU"
  },
});

var publicFileDownload = axios.create({
  baseURL: BASE_URL,

  headers: {
    "Content-Type": "application/zip",
    "cache-control": "no-cache",
    "token": "fcQPP1X0MtA6wuhg8ivQTU"
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return error;
  }
);

multiPartInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return error;
  }
);

publicInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.dismiss();

      //don't give success message on these, too annoying
      if(response.data.message !== "User Logged In Successfully"){
        if(!response.data.message.includes("Retrieved")){
          //toast.success("successMsg:" + response.data.message);
        }
      }
    }
    
    return response.data;
  },
  (error) => {
    toast.dismiss();
    if (error.response?.status === 401) {
      localStorage.removeItem("access_token");
      window.location.reload();
    } else if (error.response?.data?.error_description) {
      toast.error("1:" + error.response.data.error_description, {
        toastId: error.response.data.error_description,
      });
    } else if (error.response?.data?.message) {
      toast.error("2:" + error.response?.data?.message, {
        toastId: error.response?.data?.message,
      });
    } else if (error.response?.data?.error) {
      toast.error("3:" + error.response.data.error, {
        toastId: error.response.data.error,
      });
    } else if (error.response?.data) {
      toast.error("4:" + error.response?.data, {
        toastId: error.response?.data,
      });
    } else if (error.message) {
      toast.error("5:" + error.message, {
        toastId: error.message,
      });
    }
    throw error;
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.dismiss();

      //don't give success message on these, too annoying
      if(response.data.message !== "User Logged In Successfully"){
        if(!response.data.message.includes("Retrieved")){
          //toast.success("successMsg:" + response.data.message);
        }
      }
    }
    return response.data;
  },
  (error) => {
    toast.dismiss();
    if (error.response?.status === 401) {
      localStorage.removeItem("access_token");
      window.location.reload();
    } else if (error.response?.data?.error_description) {
      toast.error("1:" + error.response.data.error_description, {
        toastId: error.response.data.error_description,
      });
    } else if (error.response?.data?.message) {
      toast.error("2:" + error.response?.data?.message, {
        toastId: error.response?.data?.message,
      });
    } else if (error.response?.data?.error) {
      toast.error("3:" + error.response.data.error, {
        toastId: error.response.data.error,
      });
    } else if (error.response?.data) {
      toast.error("4:" + error.response?.data, {
        toastId: error.response?.data,
      });
    } else if (error.message) {
      toast.error("5:" + error.message, {
        toastId: error.message,
      });
    }
    throw error;
  }
);

multiPartInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.dismiss();

      //don't give success message on these, too annoying
      if(response.data.message !== "User Logged In Successfully"){
        if(!response.data.message.includes("Retrieved")){
          //toast.success("successMsg:" + response.data.message);
        }
      }
    }
    return response.data;
  },
  (error) => {
    toast.dismiss();
    if (error.response?.status === 401) {
      localStorage.removeItem("access_token");
      window.location.reload();
    } else if (error.response?.data?.error_description) {
      toast.error("1:" + error.response.data.error_description, {
        toastId: error.response.data.error_description,
      });
    } else if (error.response?.data?.message) {
      toast.error("2:" + error.response?.data?.message, {
        toastId: error.response?.data?.message,
      });
    } else if (error.response?.data?.error) {
      toast.error("3:" + error.response.data.error, {
        toastId: error.response.data.error,
      });
    } else if (error.response?.data) {
      toast.error("4:" + error.response?.data, {
        toastId: error.response?.data,
      });
    } else if (error.message) {
      toast.error("5:" + error.message, {
        toastId: error.message,
      });
    }
    throw error;
  }
);

export {
  instance,
  multiPartInstance,
  publicInstance, 
  publicFileDownload
};
