//3rd party imports
import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useQuery } from "react-query";


import {
  Icon
} from "@mui/material";

//components
import Grid from "@mui/material/Grid";
import PageTitle from "../../components/GenericComponents/PageTitle/index";
import styled from "styled-components";
import Button from "../../components/GenericComponents/Button/index";
import Modal from "components/GenericComponents/Modal/index";

import { getStockMakeImagesData } from "api/api";

import Form from "../../components/StockMake/StockMakeForm/index";
import EditStockMake from "../../components/StockMake/EditStockMakeProperties/index.js";
import StockMakesContext from "context/stockMakes";

const List = React.lazy(() => import("../../components/StockMake/StockMakeList/index"));

const ButtonContainer = styled.div`
margin-left: 6px;
margin-bottom: 8px;
margin-top: -38px;
float: right;
}
`;

const Title = styled.div`
  text-align: center;
  font-size: larger;
`;
export default function Index() {
  const [modal, setModal] = React.useState(false);
  const [selectedStockMake, setSelectedStockMake] = React.useState(null);

  let { currentSelectedStockMake } = React.useContext(StockMakesContext);

  const stockMakeImagesData = useQuery("getStockMakeImagesData", getStockMakeImagesData, {});

  const handleSelectStockMake = (stockMake) => {
    setSelectedStockMake(stockMake);
  };

  return (
    <>
      <React.Suspense>
        <PageTitle title="Stock Make Photo Management" />
        <Modal
          open={modal}
          onclose={() => {
            setModal(false);
          }}
          title={
            <>
              <span>Add Make</span>
            </>
          }
        >
          <Form
            onSubmit={() => {
              stockMakeImagesData.refetch();
              setModal(false);
            }}
          />
        </Modal>
        <ButtonContainer>
          <Button
            children={"Add Make"}
            borderColor="#42B94C"
            onClick={() => setModal(true)}
          />
        </ButtonContainer>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Title>Stock Make List</Title>
            <List
              listData={stockMakeImagesData?.data}
              handleSelectStockMake={handleSelectStockMake}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Edit Properties</Title>
            <EditStockMake
              name="stockMake"
              stockMakeDataRefetch={stockMakeImagesData.refetch}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Current Image</Title>
            <img src={currentSelectedStockMake?.imageUrlThumbnail} alt="Logo" />
          </Grid>
        </Grid>
      </React.Suspense>
    </>
  );
}
