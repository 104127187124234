import React from "react";
import styled from "styled-components";
const Input = styled.input`
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 0.8rem;
  padding: 0.7rem 0.75rem 0.65rem;
  line-height: 1.5;
  border: 1px solid #d7d7d7;
  background: #fff;
  color: #212121;
  width: ${(props) => (props.width ? props.width : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
`;
const FormGroup = styled.div`
  margin-bottom: 0.9rem;
  line-height: 1rem;
`;
const Required = styled.span`
  color: #dc3545;
`;

const Label = styled.label`
  margin-bottom: 0.11rem;
  color: #495057;
  font-size: 0.8rem;
`;
export const Error = styled.div`
  color: #dc3545;
  font-size: 12px;
  padding: 2px 0;
`;
export default function Inndex({
  error,
  type,
  label,
  required,
  placeholder,
  ...props
}) {
  return (
    <>
      <FormGroup>
        {label && (
          <Label htmlFor={label}>
            <span>{label}</span>
            {required && <Required className="required"> * </Required>}
          </Label>
        )}
        <Input type={type} placeholder={placeholder} {...props} />
        <Error>{error}</Error>
      </FormGroup>
    </>
  );
}
