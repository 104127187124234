import * as React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: black;
`;

export default function Index({ title }) {
  return (
    <Grid item xs={12}>
      <Title>{title}</Title>
    </Grid>
  );
}
