import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  useParams,
  useNavigate
} from "react-router-dom";
import {  useMutation, useQuery } from "react-query";
import {
  Grid,
  Button,
  TextField,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";

//mui
import TableCell from "@mui/material/TableCell";

//our stuff
import useAuth from "../../Hooks/useAuthHook";
import AddListingContext from "../../context/addListing"
import { getListingInspection, getGarageVehicle, putGarageVehicle, createListing, updateListing, getInspectionDefinition, uploadInspectionMedia } from "api/api";
import { createInspectionJob, updateInspectionJob } from "api/inspectionJob";
import { getChatGptPrompts, generateChatGPTCompletion } from "api/ai";
import UploadablePhoto from "./UploadablePhoto"

export default function AdminSingleListing() {
  const isLargeScreen = useMediaQuery("(max-width: 768px)");
  const { user, logout: authlogout } = useAuth();
  const navigate = useNavigate();

  const { 
    currentListing, setCurrentListing,
    currentListingInspectionJob, setCurrentListingInspectionJob,
    currentGarageVehicle, setCurrentGarageVehicle,
    currentInspectionDefinition, setCurrentInspectionDefinition
  } = React.useContext(AddListingContext);

  const { id } = useParams();

  const listingData = useQuery(["getListingInspection"+id], () => getListingInspection({listingId: id ? id : "" }),
  {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  });

  const chatGPTPrompts = useQuery(["getChatGptPrompts"+id], () => getChatGptPrompts({}),
  {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  })

  const inspectionDataDefinition = useQuery(["getInspectionDefinition"], () => getInspectionDefinition("DIY Listing"),
  {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  })

  useEffect(() => {
    setCurrentListing(null)
    setCurrentListingInspectionJob(null)
    setCurrentGarageVehicle(null)
    setCurrentInspectionDefinition(null)

    const retrievedListing = listingData?.data?.listing;
    const retrievedGarageVehicle = retrievedListing?.garageVehicleInfo;
    const retrievedListingInspection = retrievedListing?.inspection;
  
    const inspectionDefinition = inspectionDataDefinition?.data?.inspectionDefinition
    //console.log("retrieved inspection? " + JSON.stringify(inspectionDefinition))
    setCurrentInspectionDefinition(inspectionDefinition)
  
    if(retrievedListing){
      //console.log("listing info retrieved: " + JSON.stringify(retrievedListing));
      setCurrentListing(retrievedListing);
      
      if(retrievedGarageVehicle){
        //console.log("garage info retrieved: " + JSON.stringify(retrievedGarageVehicle));
        setCurrentGarageVehicle(retrievedGarageVehicle);
      }
  
      if(retrievedListingInspection){
        //console.log("inspection info retrieved: " + JSON.stringify(retrievedListingInspection));
        setCurrentListingInspectionJob(retrievedListingInspection);
      }
    }
  })

  const profileSchema = Yup.object().shape({
    year: Yup.string()
      .required("Please enter year"),
      //.matches(/\S/, "Invalid year")
      //.equals(4, "Must be 4 characters"),
    make: Yup.string()
      .required("Please enter make")
      .max(25, "Must be less than or equal to 25 characters"),
    model: Yup.string()
      .required("Please enter model")
      .max(50, "Must be less than or equal to 50 characters"),
    trim: Yup.string()
      .required("Please enter trim")
      .max(50, "Must be less than or equal to 50 characters"),
    vin: Yup.string()
      .nullable(),
      //.max(17, "Must be less than or equal to 17 characters"),
    location: Yup.string()
      .required("Please enter location")
      .max(50, "Must be less than or equal to 50 characters"),
  });

  const initialValues = {
    year: currentGarageVehicle?.vehicleInfo?.vehicleType?.year,
    make: currentGarageVehicle?.vehicleInfo?.vehicleType?.vehicleMake,
    model: currentGarageVehicle?.vehicleInfo?.vehicleType?.vehicleModel,
    trim: currentGarageVehicle?.vehicleInfo?.trim,
    vin: currentGarageVehicle?.vehicleInfo?.vin,
    location: currentListing?.vehiclePhysicalLocation?.title,
    privateListing: currentListing?.privateListing,
    sellerDesiredPrice: currentListing?.sellerDesiredPrice,
    mileage: currentListing?.mileage,
    color: currentGarageVehicle?.vehicleInfo?.color,
    interiorColor: currentGarageVehicle?.vehicleInfo?.interiorColor,
    description: currentListing?.description,
    originalListingUrl: currentListing?.originalListingUrl,
  };

  const { mutate: createGarageVehicleMutate } = useMutation(putGarageVehicle); //this works fine for create/update ...
  const { mutate: createInspectionJobMutate } = useMutation(createInspectionJob);
  const { mutate: updateInspectionJobMutate } = useMutation(updateInspectionJob);
  const { mutate: createListingMutate } = useMutation(createListing);
  const { mutate: updateListingMutate } = useMutation(updateListing);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: profileSchema,
    onSubmit: async (values, { resetForm }) => {
      let garageVehicleObject = createGarageVehicleObject(values);
      console.log("handling create! " + JSON.stringify(garageVehicleObject));
      //console.log("current garage vehicle " + JSON.stringify(currentGarageVehicle));
  
      //create vehicle object if not created - updates it otherwise
      createGarageVehicleMutate(
        {...garageVehicleObject},
        {
          onSuccess: (garageVehicle) => {
            console.log("return from create garage vehicle: " + JSON.stringify(garageVehicle));

            let inspectionJobObject = createInspectionJobObject(values, garageVehicle)
            
            if (currentListingInspectionJob?._id) {
              inspectionJobObject._id = currentListingInspectionJob?._id
              console.log("running update inspection job mutate: " + JSON.stringify(inspectionJobObject))
              updateInspectionJobMutate(
                { ...inspectionJobObject },
                {
                  onSuccess: (inspectionJob) => {
                    console.log("return from update inspection job: " + JSON.stringify(inspectionJob));
                    let listing = createListingObject(values, garageVehicle, inspectionJob)
                    
                    updateListingMutate(
                      { listing } ,
                      {
                        onSuccess: (listingResult) => {
                        console.log("return from update listing: " + JSON.stringify(listingResult));

                        navigateToListing(listingResult?.newListing)
                        },
                      }
                    );
                  },
                }
              );
            } else {
              console.log("running create inspection job mutate: " + JSON.stringify(inspectionJobObject))
              createInspectionJobMutate(
                { ...inspectionJobObject },
                {
                  onSuccess: (inspectionJob) => {
                    console.log("return from create inspection job: " + JSON.stringify(inspectionJob));

                    let listing = createListingObject(values, garageVehicle, inspectionJob)
                    
                    createListingMutate(
                      { listing } ,
                      {
                        onSuccess: (listingResult) => {
                        console.log("return from create listing: " + JSON.stringify(listingResult));

                        //setCurrentGarageVehicle(garageVehicle);
                        //setCurrentListing(listingResult?.newListing);

                        navigateToListing(listingResult?.newListing)
                        },
                      }
                    );
                  },
                }
              );
            }
          }
        }
      )
    },
  });


  const createGarageVehicleObject = (values) => {
    let cgv = currentGarageVehicle
    return {
      _id: currentGarageVehicle?._id,
      userId: cgv?.userId ? cgv?.userId : user?._id,
      vehicleInfo:{
        color: values?.color,
        interiorColor: values?.interiorColor,
        trim: values?.trim,
        vin: values?.vin,
        vehicleType:{
          year: values?.year,
          vehicleMake: values?.make,
          vehicleModel: values?.model,
        },
      },
      vehiclePhysicalLocation: values?.location ? {title: values?.location} : cgv?.vehiclePhysicalLocation
    }
  }
  const createInspectionJobObject = (values, garageVehicle) => {
    let cli = currentListingInspectionJob
    return {
      inspectionType: cli?.inspectionType ? cli?.inspectionType : currentInspectionDefinition, //set the definition if not yet set
      bookedBy: values?.bookedBy ? values?.bookedBy : cli?.bookedBy,
      mechanicId: values?.mechanicId ? values?.mechanicId : cli?.mechanicId,
      vehicleInfo: garageVehicle?.vehicleInfo ? garageVehicle?.vehicleInfo : cli?.vehicleInfo,
      customerInfo: values?.customerInfo ? values?.customerInfo : cli?.customerInfo,
      jobStatus: "completed",
      userId: cli?.userId ? cli?.userId : user?._id,
      inspectionClassification: values?.inspectionClassification ? values?.inspectionClassification : cli?.inspectionClassification,
      reviewStatus: values?.reviewStatus ? values?.reviewStatus : cli?.reviewStatus,
      vehiclePhysicalLocation: values?.location ? {title: values?.location} : garageVehicle?.vehiclePhysicalLocation,
    }
  }
  const createListingObject = (values, garageVehicle, inspectionJob) => {
    let clo = currentListing
    return {
      userId: clo?.userId ? clo?.userId : user?._id,
      vin: values?.vin ? values?.vin : clo?.vin,
      garageVehicleInfo:  garageVehicle?.vehicleInfo ? garageVehicle?.vehicleInfo : clo?.vehicleInfo,
      vehicleYear:  garageVehicle?.vehicleInfo?.vehicleType?.year,
      dateCreated:  clo?.dateCreated ? clo?.dateCreated : null,
      description:  values?.description ? values?.description : clo?.description,
      isActive:  values?.isActive ? values?.isActive : clo?.isActive? clo?.isActive : false,
      mileage:  values?.mileage ? values?.mileage : clo?.mileage,
      sellerDesiredPrice:  values?.sellerDesiredPrice ? values?.sellerDesiredPrice : clo?.sellerDesiredPrice,
      photosInspectionId:  inspectionJob?._id ? inspectionJob?._id : clo?.photosInspectionId,
      vehiclePhysicalLocation: values?.location ? {title: values?.location} : clo?.vehiclePhysicalLocation,
      originalListingUrl: values?.originalListingUrl ? values?.originalListingUrl : clo?.originalListingUrl
    }
  }
  const navigateToListing = (listing) => {
      if(listing?._id) {
        let page = "/adminSingleListing/" + listing?._id
        navigate(`${page}`)
      }
  }

  const handleCopyLink = (value) => {
    console.log("handling copy! " + JSON.stringify(value))
  };

  const handleActivateListing = (value) => {
    //let listing = createListingObject(values, garageVehicle, inspectionJob)
    var listing = currentListing
    listing.isActive = !listing.isActive

    updateListingMutate(
      { listing } ,
      {
        onSuccess: (listingResult) => {
        console.log("return from activating listing: " + JSON.stringify(listingResult));
        setCurrentListing(listingResult?.newListing);
        },
      }
    );
  };  

  const generateNewGPTDescription = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        var prompts = chatGPTPrompts?.data?.items
        
        console.log("generating chatgpt prompt")
    
        if(prompts?.length > 0){
          var prompt = prompts[0]
          if ( prompt.sections?.length > 1 ) {
            var newDescription = ""
            prompt.sections[1].categories?.forEach(category => {
              if(category.categoryName == "default prompt") {
                if(category.description){
                  var vehicleType = currentGarageVehicle?.vehicleInfo.vehicleType
                  var chatGPTPrompt = category.description + ": " + vehicleType.year + " " + vehicleType.vehicleMake + " " + vehicleType.vehicleModel
                  
                  if(currentListing?.mileage){ chatGPTPrompt += (" mileage: " + currentListing?.mileage.description) }

                  resolve(generateChatGPTCompletion({ "prompt": chatGPTPrompt}))
                }
              }
            });      
          }
        }
      }, 1000);
    });      
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Grid container={isLargeScreen ? false : true}>
            <Grid item lg={12} md={12} xs={12} sx={{ px: 2, py: 1, mt: 4 }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Add Vehicle
                  </span>
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              sx={{ px: 2, py: 2, marginBottom: "10px" }}
              className="input_fields">
              <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Year
                  </span>
                  <TextField
                    id="year"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    name="year"
                    placeholder="Year"
                    value={formik.values && formik.values.year}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.year && formik.errors.year
                    )}
                    helperText={
                      formik.touched.year && formik.errors.year ? (
                        <span className="error">
                          {formik.errors.year?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Make
                  </span>
                  <TextField
                    id="make"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    placeholder="Make"
                    name="make"
                    value={formik.values && formik.values.make}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.make && formik.errors.make
                    )}
                    helperText={
                      formik.touched.make && formik.errors.make ? (
                        <span className="error">
                          {formik.errors.make?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Model
                  </span>
                  <TextField
                    id="model"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    name="model"
                    placeholder="Model"
                    value={formik.values && formik.values.model}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.model && formik.errors.model
                    )}
                    helperText={
                      formik.touched.model && formik.errors.model ? (
                        <span className="error">
                          {formik.errors.model?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Trim
                  </span>
                  <TextField
                    id="trim"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    placeholder="Trim"
                    name="trim"
                    value={formik.values && formik.values.trim}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.trim && formik.errors.trim
                    )}
                    helperText={
                      formik.touched.trim && formik.errors.trim ? (
                        <span className="error">
                          {formik.errors.trim?.toString()}
                        </span>
                      ) : null
                    }
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Mileage
                  </span>
                  <TextField
                    id="mileage"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    name="mileage"
                    placeholder=""
                    value={formik.values && formik.values.mileage}
                    onChange={formik.handleChange}
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Desired Price
                  </span>
                  <TextField
                    id="sellerDesiredPrice"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    placeholder="seller price"
                    name="sellerDesiredPrice"
                    value={formik.values && formik.values.sellerDesiredPrice}
                    onChange={formik.handleChange}
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Exterior Color
                  </span>
                  <TextField
                    id="color"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    name="color"
                    placeholder=""
                    value={formik.values && formik.values.color}
                    onChange={formik.handleChange}
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Interior Color
                  </span>
                  <TextField
                    id="interiorColor"
                    //   variant="filled"
                    sx={{ backgroundColor: "#F4F4F4" }}
                    margin="dense"
                    placeholder="interiorColor"
                    name="interiorColor"
                    value={formik.values && formik.values.interiorColor}
                    onChange={formik.handleChange}
                    fullWidth
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Vin
                </span>
                <TextField
                  id="vin"
                  sx={{ backgroundColor: "#F4F4F4" }}
                  placeholder="Vin"
                  margin="dense"
                  name="vin"
                  value={formik.values && formik.values.vin}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.vin && formik.errors.vin)}
                  helperText={
                    <span className="error">
                      {formik.errors.vin?.toString()}
                    </span>
                  }
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Original Listing URL
                </span>
                <TextField
                  id="originalListingUrl"
                  sx={{ backgroundColor: "#F4F4F4" }}
                  placeholder="https://somecarlisting"
                  margin="dense"
                  name="originalListingUrl"
                  value={formik.values && formik.values.originalListingUrl}
                  onChange={formik.handleChange}
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Location
                </span>
                <TextField
                  id="location"
                  sx={{ backgroundColor: "#F4F4F4", border: "none" }}
                  margin="dense"
                  name="location"
                  placeholder="Location"
                  value={formik.values && formik.values.location}
                  onChange={formik.handleChange}
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>

              <div style={{ display: "flex", gap: "10px", marginBottom: "30px" }}>
                <Button
                  type="submit"
                  sx={{
                    mt: 2,
                    float: "right",
                    backgroundColor: "#87AA8D",
                    color: "white",
                    textTransform: "capitalize",
                    padding: "7px 15px",
                    borderRadius: "6px",
                  }}>
                  { currentListing?.vin ? "Update Listing" : "Create Listing" }
                </Button>
              </div>

              <div style={{ display: "flex", gap: "10px", marginBottom: "30px" }}>
                <Button
                  onClick={ () => generateNewGPTDescription().then(result => {
                    console.log("RESULT: " + JSON.stringify(result))
                    formik.setFieldValue("description", result?.completionResponse)
                  }) }
                  sx={{
                    mt: 2,
                    float: "right",
                    backgroundColor: "#87AA8D",
                    color: "white",
                    textTransform: "capitalize",
                    padding: "7px 15px",
                    borderRadius: "6px",
                  }}>
                  { currentListing?.description ? "Generate new description" : "Generate a description" }
                </Button>
              </div>

              {
                currentListing?.isActive ? (
                  null
                ) : (
                <div style={{ display: "flex", gap: "10px", marginBottom: "30px" }}>
                  <Button
                    onClick={ () => {} }
                    sx={{
                      mt: 2,
                      float: "right",
                      backgroundColor: "red",
                      color: "white",
                      textTransform: "capitalize",
                      padding: "7px 15px",
                      borderRadius: "6px",
                    }}>
                    Delete Listing
                  </Button>
                </div>

              )}


              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Vehicle Description
                </span>
                <TextareaAutosize
                  rows="4"
                  id="description"
                  sx={{ backgroundColor: "#F4F4F4", border: "none" }}
                  margin="dense"
                  name="description"
                  placeholder=""
                  value={formik.values && formik.values.description}
                  onChange={formik.handleChange}
                  fullWidth
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </div>

            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              sx={{ px: 2, py: 2, marginBottom: "10px" }}
              className="input_fields">

              <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Current Listing URL:
                </span>

                <TextField
                  id="url"
                  sx={{ backgroundColor: currentListing?.isActive ? "#F4F4F4" : "#FFB3B3" }}
                  placeholder="Url"
                  margin="dense"
                  name="url"
                  value={ currentListing?.isActive ? `https://listings.checkdrive.co/availableListing/listingDetails/used-${currentGarageVehicle?.vehicleInfo?.vehicleType?.vehicleMake}-${currentGarageVehicle?.vehicleInfo?.vehicleType?.vehicleModel}-${currentGarageVehicle?.vehicleInfo?.vehicleType?.year}-${currentGarageVehicle?.vehicleInfo?.vin.slice(-10)}` : "This Listing Not Active"}
                  disabled="true"
                  fullWidth
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    required: true,
                  }}
                />

                <Button
                  // loading={btnLoading}
                  onClick={() => handleActivateListing()}
                  sx={{
                    mt: 2,
                    float: "right",
                    backgroundColor: "#87AA8D",
                    color: "white",
                    textTransform: "capitalize",
                    padding: "7px 15px",
                    borderRadius: "6px",
                  }}>
                  { currentListing?.isActive ? "Deactivate" : "Activate" }
                </Button>
              </div>
                  
              <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Inspection Type Name: {currentListingInspectionJob?.inspectionType?.inspectionName}
                </span>
              </div>
                

              {currentListingInspectionJob?.inspectionType?.sections.map(section => {
                  return section.sectionName ? (
                    <div key={section._id} style={{ marginBottom: "20px" }}>
                      <div>
                        <span style={{ fontSize: "17px", fontWeight: "800" }}>
                          Section Photos {section.sectionName}
                        </span>
                      </div>
                      {section.categories.map(category => {
                        return category.categoryName && currentListingInspectionJob ? (
                          <div key={category._id} style={{ display: 'flex', alignItems: 'center', verticalAlign: "center", fontSize: "10px", fontWeight: "800", marginBottom:"2px" }}>
                            <UploadablePhoto 
                              inspection={currentListingInspectionJob}
                              section={section}
                              category={category}
                            />
                          </div>
                        ) : null
                      })}
                    </div>
                  ) : null
                })
              }
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
}

/*


              <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Primary Photo
                  <input
                    type="file"
                    name="singleImage"
                    id="singleImage"
                    onChange={ (e)=>{
                      if (e.target.files) {
                        setSelectedImage(e.target.files[0]);
                        //setSelectedImageUrl(URL.createObjectURL(e.target.files[0]))
                      }
                    }}
                  />
                </span>
                {currentListingInspectionJob?.inspectionType?.sections ? (
                  <div>
                    <img
                      alt="not found"
                      width={"60px"}
                      src={selectedImageUrl}
                    />

                    <button onClick={handleUploadClick}>Upload</button>

                    <button onClick={() => setSelectedImage(null)}>Remove</button>
                  </div>
                ) : ""
                }
              </div>

              <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  Other Photos
                  <input
                    type="file"
                    name="multiImage"
                    id="multiImage"
                    multiple
                    onChange={ (e)=>{              
                      if(e){
                        //setSelectedImages([...selectedImages, ...e.target.files])
                      }
                    }}
                  />
                </span>

                {currentListingInspectionJob?.inspectionType?.sections ? (
                  <div>
                      {currentListingInspectionJob?.inspectionType?.sections[0].categories?.map((category) => (
                        <div>
                          <img
                            alt="not found"
                            width={"30px"}
                            src={category?.photoThumbnail}
                          />
                        </div>
                      ))}

                    <button onClick={handleUploadClick}>Upload</button>

                    <button onClick={() => setSelectedImages([])}>Remove</button>
                  </div>
                ) : ""
                }
              </div>

              */