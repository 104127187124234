import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";

const ServiceBox = () => {
  return (
    <Grid item lg={5} md={6} xs={10}>
      <Card variant="outlined" sx={{ padding: "10px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "21px",
          }}
        >
          <Typography>DMV Paperwork</Typography>
          <Typography fontWeight={"bold"}>$245.00</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "62px",
            marginLeft: "12px",
            marginRight: "12px",
            marginBottom: "2.8em",
            position: "relative",
          }}
        >
          <Typography fontSize={14}>
            Create a listing using our listing application. Whether you are an
            Android or an Apple user, click the link to download our application
            and get started
          </Typography>
          <Button
            variant=" contained"
            size="small"
            sx={{
              backgroundColor: "#F4F4F4",
              borderRadius: "12px",
              marginTop: "4px",
              position: "absolute",
              right: "0",
            }}
          >
            Coming soon
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

export default ServiceBox;
