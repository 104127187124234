import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";

//material
import Grid from "@mui/material/Grid";
import Input from "components/GenericComponents/Input/index";
import CheckboxesGroup from "components/GenericComponents/checkbox/groupCheckbox";
import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import Modal from "components/GenericComponents/Modal/index";

import { createStockMakeImageData, putStockMakeImage } from "api/api";
import StockMakeContext from "context/stockMakes";

const ButtonContainer = styled.div`
text-align: center;
 }
`;
const AddNewButtonContainer = styled.div`
  float: right;
  margin-top: -16px;
  margin-right: 23px;
`;
const InputContainer = styled.div`
  margin-left: 7%;
`;

export default function Index({
  data = null,
  onSubmit,
  disabled = false,
  btnText,
  handleDisabled,
  type = "add",
}) {
  let {
    setEditableProperties,
    setCurrentSelectedStockMake,
    setIndexToLatestAdded,
  } = React.useContext(StockMakeContext);

  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };

  const UploadAndDisplayImage = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleUploadClick = () => {
      if (!selectedImage) {
        return;
      }
  
      // 👇 Uploading the file using the fetch API to the server
      fetch('https://http.org/post', {
        method: 'POST',
        body: selectedImage,
        // 👇 Set headers manually for single file upload
        headers: {
          'content-type': selectedImage.type,
          'content-length': `${selectedImage.size}`, // 👈 Headers need to be a string
        },
      })
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((err) => console.error(err));
    };

    const importFile = event => {
      console.log("HERE!" + JSON.stringify(event))

      if(event){
        console.log(event.target.files[0]); // Log the selected file
        setSelectedImage(event.target.files[0]); // Update the state with the selected file
      }
    };

    return (
      <div>
        <h1>Upload New Image</h1>
  
        {selectedImage ? (
          <div>
            {selectedImage && `${selectedImage.name} - ${selectedImage.type}`}
            {/* Display the selected image */}
            <img
              alt="not found"
              width={"250px"}
              src={URL.createObjectURL(selectedImage)}
            />

            <button onClick={handleUploadClick}>Upload</button>

            <button onClick={() => setSelectedImage(null)}>Remove</button>
          </div>
        ) : ""
        }
  
        <br />
  
        <input
          type="file"
          name="singleImage"
          id="singleImage"
          onChange={ (e)=>{
            console.log("HERE!" + JSON.stringify(e))
      
            if(e){
              console.log(e.target.files[0]); // Log the selected file
              setSelectedImage(e.target.files[0]); // Update the state with the selected file
            }
          }}
        />

      </div>
    );
  }

  const initialValues = {
    makeName: data?.makeName || "",
    matchingTags: data?.matchingTags || "",
  };

  const validationSchema = Yup.object({
    makeName: Yup.string().required("Make Name is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      console.log("submitting formik! Data: " + JSON.stringify(data));
      console.log("submitting formik! values: " + JSON.stringify(values));

      if (data) {
        updateStockMakeMutate(
          { ...values, stockMakeId: data?._id },
          {
            onSuccess: (result) => {
              console.log("Success! Data: " + JSON.stringify(data));

              setEditableProperties(result?.stockImage);
              setCurrentSelectedStockMake(result?.stockImage);
              onSubmit();
            },
          }
        );
      } else {
        createStockMakeMutate(
          { ...values },
          {
            onSuccess: (result) => {
              setIndexToLatestAdded();
              setEditableProperties(result?.stockImage);
              setCurrentSelectedStockMake(result?.stockImage);
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
   
  const { mutate: createStockMakeMutate } = useMutation(createStockMakeImageData);
  const { mutate: updateStockMakeMutate } = useMutation(putStockMakeImage);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>

        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <Input
              label="Make Name: "
              required
              name="makeName"
              onChange={formik.handleChange}
              value={formik.values.makeName}
              error={
                formik.touched.makeName && formik.errors.makeName
                  ? formik.errors.makeName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Additional Tags (comma seperated): "
              name="matchingTags"
              onChange={formik.handleChange}
              value={formik.values.matchingTags}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadAndDisplayImage />
          </Grid>

          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Make"}
                type="submit"
                display={disabled ? "none" : ""}
                borderColor="#42B94C"
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>

        </Grid>
      </form>
    </>
  );
}



/*


            <Button
              children={"Upload New Photo"}
              type="button"
              marginLeft="12px"
              backgroundColor="#42B94C"
              color="white"
              hoverEffect="background:#addeb1;:white;"
              borderColor="#42B94C"
              onClick={() => handleUploadPhoto()}
            />



            */