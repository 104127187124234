
import { React, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Bookmark } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import ListingsContext from "context/listings";
import logo from "assets/images/front.png";
import useAuth from "Hooks/useAuthHook";
import { addFavorite, removeFavorite } from "api/api";

import "./ListingsCard.css";

export function get_image_urls(
  inspection = "",
  mediaType = "photoUrl",
  photoWeightedOnly = true
) {
  var ourPhotoURLs = [];

  inspection?.inspectionType?.sections.forEach((section) => {
    if (section?.$mediaType && section?.sectionName) {
      ourPhotoURLs.push([section?.photoUrl, section?.sectionName]);
    }

    section?.categories.forEach((category) => {
      if (
        category?.photoUrl &&
        category?.categoryName &&
        category?.photoWeightEnabled === "Yes"
      ) {
        ourPhotoURLs.push([category?.photoUrl, category?.categoryName]);
      }
    }); //end category
  }); //end section

  return ourPhotoURLs;
}

const ListingsCard = ({ listing, isBookmarked, handleRemove, index }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [bookmark, setBookmark] = useState(isBookmarked ? isBookmarked : false);

  let { setCurrentSelectedListing } = useContext(ListingsContext);

  const handleSelectListing = (listing) => {
    setCurrentSelectedListing(listing);
  };

  //console.log("index1111", index);
  console.log("isBookmarked", isBookmarked);

  const imageUrls = get_image_urls(listing?.inspection);
  function extraUrlChars(listing) {
    let make = listing?.inspection?.vehicleInfo?.vehicleType?.vehicleMake;
    let model = listing?.inspection?.vehicleInfo?.vehicleType?.vehicleModel;
    let year = listing?.inspection?.vehicleInfo?.vehicleType?.year;
    let vinLastTen = listing?.inspection?.vehicleInfo?.vin.slice(-10);
    var extraChars =
      "used-" + make + "-" + model + "-" + year + "-" + vinLastTen;
    return extraChars;
  }
  //console.log("ghjfgjhfghf");

  const handleNavigate = () => {
    handleSelectListing(listing);
    console.log("__123456uiouiouyuityi", extraUrlChars(listing), listing);
    navigate("/availableListing/listingDetails/" + extraUrlChars(listing), {
      state: { path: location.pathname, bookmark: bookmark },
    });
  };
  let mainImage = null;
  if (imageUrls.length > 0) {
    mainImage = imageUrls[0][0];
  }

  const handleBookMark = async () => {
    if (!isAuthenticated) {
      sessionStorage.setItem("car_id", listing?.vin);
      return navigate("/auth/login");
    } else {
      await addFavorite(user?._id, listing?.vin);
      toast.success("Car added to Favorites");
      setBookmark(true);
    }
  };

  const handleRemoveFavourites = async () => {
    if (location.pathname === "/myGarage") {
      await removeFavorite(user?._id, listing?.vin);
      handleRemove(listing._id);
    } else {
      await removeFavorite(user?._id, listing?.vin);
    }
    if (listing?.vin) {
    }

    toast.warn("Car removed from Favorites");

    setBookmark(false);
  };
  console.log(listing?.inspection?.vehicleInfo?.vehicleType, "list==");
  return (
    <div className=" card__container">
      <div className="image__container" onClick={() => handleNavigate()}>
        <img
          src={mainImage ? mainImage : logo}
          alt="car"
          className="car__img"
        />
        <span className="vin">
          {/* <span style={{ fontWeight: "bold" }}>VIN:</span>{" "}
          {listing?.vin.toUpperCase()} */}
        </span>
      </div>
      <div
        className="bookmark"
        onClick={bookmark === true ? handleRemoveFavourites : handleBookMark}>
        {bookmark === true ? <Bookmark /> : <BookmarkBorderOutlinedIcon />}
      </div>
      <div className="details--container" onClick={() => handleNavigate()}>
        <p className="model__text" style={{ fontWeight: "bold" }}>
          {listing?.inspection?.vehicleInfo?.vehicleType?.year}{" "}
          {listing?.inspection?.vehicleInfo?.vehicleType?.vehicleMake}{" "}
          {listing?.inspection?.vehicleInfo?.vehicleType?.vehicleModel}
        </p>

        <div className=" specs__container">
          <img
            src="/speedometer.png"
            alt="Speedometer"
            style={{ width: "20px", objectFit: "contain" }}
          />
          <span style={{ color: "#969696" }}>
            {listing?.mileage
              ?.toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            Miles
          </span>
        </div>

        <div className="specs__container">
          <LocationOnOutlinedIcon sx={{ color: "#87AC8D", fontSize: "20px" }} />
          <span style={{ color: "#969696" }}>
            {listing?.vehiclePhysicalLocation?.city},{" "}
            {listing?.vehiclePhysicalLocation?.state}
          </span>
        </div>
      </div>
      <div className="btn__container">
        <button className="price__btn" style={{ fontWeight: 600 }}>
          $
          {listing?.sellerDesiredPrice
            ?.toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </button>
      </div>
    </div>
  );
};

export default ListingsCard;
