import axios from "axios";
import { instance, publicInstance, publicFileDownload } from "./axiosConfig";



/*
AI API Calls
*/  
  export async function getChatGptPrompts() {
    let { data } = await instance.get(`/ai/getChatGptPrompts`);
    return data;
  }
  /*export async function createShop(_data) {
    let { data } = await instance.post("/users/createShop", _data);
    return data;
  }*/
 //generateChatGPTCompletion(prompt: String)
  export async function generateChatGPTCompletion(_data) {
    let { data } = await instance.put(`/ai/generateChatGPTText`, _data);
    return data;
  }