import axios from "axios";
import { instance, publicInstance, publicFileDownload } from "./axiosConfig";


/*
USER API FUNCTIONS
*/


/*
Shop API Calls
*/  
  export async function getShops() {
    let { data } = await instance.get(`/users/getShops`);
    return data;
  }
  export async function createShop(_data) {
    let { data } = await instance.post("/users/createShop", _data);
    return data;
  }
  export async function updateShop(_data) {
    let { data } = await instance.put(`/users/updateShop/${_data.shopId}`, _data);
    return data;
  }
  export async function getGroups(shopId) {
    let { data } = await instance.get(`/users/getGroups?shopId=${shopId}`);
    return data;
  }
  export async function createShopGroup(_data) {
    let { data } = await instance.post("/users/createShopGroup", _data);
    return data;
  }
  export async function updateShopGroup(_data) {
    let { data } = await instance.put(`/users/updateShopGroup/${_data.groupId}`, _data);
    return data;
  }