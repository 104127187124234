import React from "react";
import AddListingContext from "context/addListing";

export default function AddListingProvider({ children }) {

  const [currentListing, setCurrentListing] = React.useState(null);
  const [currentListingInspectionJob, setCurrentListingInspectionJob] = React.useState(null);
  const [currentGarageVehicle, setCurrentGarageVehicle] = React.useState(null);
  const [currentInspectionDefinition, setCurrentInspectionDefinition] = React.useState(null);

  return (
    <>
      <AddListingContext.Provider
        value={{
          currentListing, setCurrentListing,
          currentGarageVehicle, setCurrentGarageVehicle,
          currentListingInspectionJob, setCurrentListingInspectionJob,
          currentInspectionDefinition, setCurrentInspectionDefinition
        }}
      >
        {children}
      </AddListingContext.Provider>
    </>
  );
}
