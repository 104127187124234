import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CarCard from "./CarCard_";
import ListingsCard from "../Listings/components/ListingsCard/ListingsCard";

import { getGarageModels, getMylistings } from "api/api";
import { getFavoritesListings } from "api/api";
import { Button, Typography } from "@mui/material";

// import { useNavigate } from "react-router-dom";
//import BreadCrumbs from "../../components/BreadCrumbs";

// import GarageContext from "context/garage";
import AuthContext from "context/autho";
import GarageHeader from "./components/GarageHeader";
import TableCell from "@mui/material/TableCell";

export default function CarGarage() {
  const [value, setValue] = useState("1");

  // let navigate = useNavigate();

  // const handleNavigate = () => {
  //   navigate("/myGarage/saleDetails");
  // };

  // let { currentSelectedVehicle  } = React.useContext(GarageContext);
  const { user } = React.useContext(AuthContext);
  console.log("user is: " + JSON.stringify(user));
  const [saved, setSaved] = useState([]);

  const garageModels = useQuery(
    "getGarageModels",
    () => getGarageModels(user?._id),
    {}
  );

  const myListings = useQuery("myListings", () => getMylistings(user?._id), {});
  const myInactiveListings = useQuery(
    "myInactiveListings",
    () => getMylistings(user?._id, {}, false),
    {}
  );

  console.log("Garage Models Fetch: " + JSON.stringify(garageModels));
  console.log("MyListings Fetch: " + JSON.stringify(myListings));

  const fetchData = async () => {
    const res = await getFavoritesListings(user._id);
    console.log("res___________", res);
    setSaved(res.listings);
  };

  const remove = async (id) => {
    console.log("id1234", id);
    const updatedItems = saved.filter((item) => item._id !== id);
    console.log("updatedItems", updatedItems);
    setSaved(updatedItems);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GarageHeader />
      <div style={{ marginLeft: "1em" }}>
        <Box display={"flex"} gap={"8px"}>
          <Button
            onClick={() => setValue("1")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "1" ? "#F4FFF4" : "#F4F4F4",
              color: value === "1" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            My Vehicles
          </Button>
          <Button
            onClick={() => setValue("2")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "2" ? "#F4FFF4" : "#F4F4F4",
              color: value === "2" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Active Listing
          </Button>
          <Button
            onClick={() => setValue("3")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "3" ? "#F4FFF4" : "#F4F4F4",
              color: value === "3" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Inactive Listing
          </Button>
          <Button
            onClick={() => setValue("4")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "4" ? "#F4FFF4" : "#F4F4F4",
              color: value === "4" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Sold
          </Button>
          <Button
            onClick={() => setValue("5")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "5" ? "#F4FFF4" : "#F4F4F4",
              color: value === "5" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Saved Listings
          </Button>
        </Box>
        <Box>
          {value === "1" && (
            <Grid
              container
              lg={12}
              md={12}
              xs={12}
              sx={{ display: "flex" }}
              className="vehiclecard"
              gap={"10px"}>
              {garageModels?.data?.garageModels?.map((garageModel) => (
                <CarCard vehicleRecord={garageModel} />
              ))}
            </Grid>
          )}

          <Box
            sx={{
              width: "100%",
              marginInline: "auto",
              marginTop: "10px",
              display: "flex",
              justifyContent: { xs: "center", sm: "center", md: "start" },
              alignItems: "center",
            }}>
            {value === "2" && (
              <Box
                sx={{
                  width: "100%",
                  flex: 1,
                  marginInline: "auto",
                  paddingInline: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                }}>
                {myListings?.length === 0 || !myListings ? (
                  <Typography
                    fontSize={24}
                    color="#87AC8D"
                    sx={{ marginInline: "auto", marginTop: "4em" }}>
                    No Active Listings, create one to sell your car!
                  </Typography>
                ) : (
                  myListings?.data?.listings?.map((listing) => (
                    <ListingsCard
                      key={listing._id}
                      listing={listing}
                      isBookmarked={false}
                    />
                  ))
                )}
              </Box>
            )}

            {value === "3" && (
              <Box
                sx={{
                  width: "100%",
                  flex: 1,
                  marginInline: "auto",
                  paddingInline: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                }}>
                {myListings?.length === 0 || !myListings ? (
                  <Typography
                    fontSize={24}
                    color="#87AC8D"
                    sx={{ marginInline: "auto", marginTop: "4em" }}>
                    No Inactive Listings
                  </Typography>
                ) : (
                  myInactiveListings?.data?.listings?.map((listing) => (
                    <ListingsCard
                      key={listing._id}
                      listing={listing}
                      isBookmarked={false}
                    />
                  ))
                )}
              </Box>
            )}

            {value === "5" && (
              <Box
                sx={{
                  width: "100%",
                  flex: 1,
                  marginInline: "auto",
                  paddingInline: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                }}>
                {saved?.length === 0 || !saved ? (
                  <Typography
                    fontSize={24}
                    color="#87AC8D"
                    sx={{ marginInline: "auto", marginTop: "4em" }}>
                    No Favorites
                  </Typography>
                ) : (
                  saved?.map((favorites, i) => (
                    <>
                      <ListingsCard
                        key={favorites._id}
                        listing={favorites}
                        isBookmarked={true}
                        handleRemove={remove}
                      />
                    </>
                  ))
                )}
              </Box>
            )}
          </Box>
        </Box>
      </div>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
}
