import React from "react";
import StockMakeContext from "context/stockMakes";
import { getStockMakeImagesData } from "api/api";

export default function StockMakesProvider({ children }) {

  //all currently editable properties
  const [editableProperties, setEditableProperties] = React.useState(null);

  //currently selected make
  const [currentSelectedStockMake, setCurrentSelectedStockMake] =
    React.useState(null);

    
  const [unUsedIndex, setUnsedIndex] = React.useState(null);
  const [unUsedCatIndex, setUnsedCatIndex] = React.useState(null);

  const [listData, setListData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const setIndexToLatestAdded = async () => {
    const StockMakeData = await getStockMakeImagesData();

    setSelectedIndex(StockMakeData.length > 0 ? StockMakeData?.length - 1 : 0);
  };

  const setFirstEditableProperty = async () => {
    const StockMakeData = await getStockMakeImagesData();

    setEditableProperties(
      StockMakeData.length > 0 ? StockMakeData[0] : null
    );
  };
  
  return (
    <>
      <StockMakeContext.Provider
        value={{
          editableProperties,
          setEditableProperties,
          currentSelectedStockMake,
          setCurrentSelectedStockMake,
          selectedIndex,
          setSelectedIndex,
          listData,
          setListData,
          setIndexToLatestAdded,
          setFirstEditableProperty,
          unUsedIndex: unUsedIndex,
          setUnsedIndex,
          unUsedCatIndex: unUsedCatIndex,
          setUnsedCatIndex,
        }}
      >
        {children}
      </StockMakeContext.Provider>
    </>
  );
}
