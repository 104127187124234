import * as React from "react";
import Box from "@mui/material/Box";
import AdminListingsCard from "./components/ListingsCard/AdminListingsCard";
import { useQuery } from "react-query";
import { getListingInspections, getFavoritesListings } from "api/api";
import { Typography } from "@mui/material";
import useAuth from "../../Hooks/useAuthHook";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Filter from "./components/Filters/Filter";

const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "0",
    },
  });
});

export default function ResponsiveGrid() {
  // listingFilters - { vin, jobStatus, make, model, year, dateFrom, dateTo, inspectionType, mileageFrom, mileageTo, priceFrom, priceTo }
  // /listing/getListingInspections?listingFilters={ "mileageTo":1500000, "priceTo":1000000 }
  const listingsData = []
  //useQuery(
  //  "getListingInspections",
  //  getListingInspections({ priceFrom: 0, priceTo: 9999999 }, "All"),
  //  {}
  //);

  const { user, isAuthenticated } = useAuth();
  const { search } = useStyles();
  const [searchText, setSearchText] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [filteredData, setFilteredData] = useState(listingsData?.listings);

  const handleFilterChange = async (term) => {
    console.log("handleFilterChange")
    if (term !== "") {
      const filtered = filteredData.filter((item) =>
        item?.inspection?.vehicleInfo.uniqueStringYearMakeModel
          .toLowerCase()
          .includes(term.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      const listingsData = await getListingInspections({ priceTo: 9999999 }, "All");
      setFilteredData(listingsData?.listings);
    }
  };

  const handleChange = (event) => {
    const term = event.target.value;
    setSearchText(term);
    handleFilterChange(term);
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const location = useLocation();

  const bookmarkedListing = useQuery(
    "getFavourites",
    () => (isAuthenticated ? getFavoritesListings(user?._id) : null),
    {}
  );

  const handleClick = () => {
    // TODO: Clear the search input
    setSearchText("");
    setShowClearIcon("none");
  };

  async function fetchFilteredInspections(filters) {
    const res = await getListingInspections(filters, filters?.isActive);
    setFilteredData(res.listings);
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 410px)",
        display: "flex",
        flexDirection: "column",
      }}>
      {isAuthenticated ? (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            marginBottom={"10px"}
            sx={{
              backgroundColor: "#fff",
              p: 1,
              marginBottom: "1em",
              borderTop: "1px solid #d6d6d6",
              borderBottom: "1px solid #d6d6d6",
              padding: "1rem",
            }}
            className="right-navbar">
            <Typography fontSize={20} fontWeight={"bold"} color={"#253528"}>
              Admin View of ALL Listings
            </Typography>
          </Box>
          <FormControl
            className={search}
            style={{
              marginBottom: "0.5em",
              width: isAuthenticated ? "95.1%" : "60%",
              marginLeft: "0.85em",
            }}>
            <TextField
              size="medium"
              variant="outlined"
              onChange={handleChange}
              value={searchText}
              style={{ width: "70%" }}
              placeholder={"Search"}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ color: "#87AC8D", borderColor: "#87AC8D" }}>
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      display: showClearIcon,
                      cursor: "pointer",
                      color: "#87AC8D",
                    }}
                    onClick={handleClick}>
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </>
      ) : (
        <></>
      )}

      <Box
        sx={{
          p: 1,
          width: "100%",
          marginInline: "auto",
          display: "flex",
          justifyContent: { xs: "center", sm: "start", md: "start" },
          alignItems: "start",
        }}>
        <Box
          display={"flex"}
          alignItems={{ xs: "start", sm: "start" }}
          justifyContent={{ xs: "start", sm: "start", md: "start" }}
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ flexWrap: "wrap", padding: "4px" }}
          gap={"5px"}>
          {!filteredData && !listingsData ? (
            <></>
          ) : (
            <Filter filter={fetchFilteredInspections} />
          )}
          <Box
            sx={{
              width: isAuthenticated ? "100%" : "74%",
              flex: isAuthenticated ? 1 : "",
              marginInline: "auto",
              paddingInline: "auto",
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: { xs: "center", sm: "center", md: "start" },
            }}>

            {filteredData
              ? filteredData?.map((item, i) => {
                  let book =
                    bookmarkedListing?.data?.listings?.find(
                      (fav) => fav._id === item._id
                    ) || item._id === location?.state?.id;
                  return (
                    <>
                      <AdminListingsCard
                        key={item._id}
                        listing={item}
                        isBookmarked={book === false ? false : true}
                        index={i}
                      />
                    </>
                  );
                })
              : listingsData?.data?.listings?.map((item, i) => {
                  let book =
                    bookmarkedListing?.data?.listings?.find(
                      (fav) => fav._id === item._id
                    ) || item._id === location?.state?.id;
                  return (
                    <>
                      <AdminListingsCard
                        key={item._id}
                        listing={item}
                        isBookmarked={book === false ? false : true}
                        index={i}
                      />
                    </>
                  );
                })}
            {!filteredData && !listingsData && (
              <Typography
                fontSize={24}
                textAlign={"center"}
                color="#87AC8D"
                sx={{ marginInline: "auto", marginTop: "4em" }}>
                No Listings
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
