import { Box, Button, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import React from "react";

const years = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = ["Any"];
  startYear = startYear || 1955;
  while (startYear <= currentYear) {
    years.push(currentYear--);
  }
  return years;
};

const mileage = [
  "Any",
  15000,
  30000,
  45000,
  60000,
  75000,
  100000,
  150000,
  200000,
];

const activeStatus = [
  "All",
  "true",
  "false"
]

const Filter = ({ filter }) => {
  const [filters, setFilters] = useState({
    priceTo: "",
    priceFrom: "",
    yearsTo: "",
    yearsFrom: "",
    mileageTo: "",
    mileageFrom: "",
    isActive: "",
  });

  const handleClear = () => {
    setFilters({
      priceTo: "",
      priceFrom: "",
      yearsTo: "",
      yearsFrom: "",
      mileageTo: "",
      mileageFrom: "",
      isActive: "",
    });
  };
  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setFilters((prev) => {
      return { ...prev, [e.target.name]: value };
    });
  };
  useEffect(() => {
    if (
      filters.priceTo !== "" ||
      filters.priceFrom !== "" ||
      filters.mileageTo !== "" ||
      filters.mileageFrom !== "" ||
      filters.yearsTo !== "" ||
      filters.priceFrom !== ""||
      filters.isActive !== ""
    ) {
      setTimeout(() => {
        filter(filters);
      }, 500);
    } else {
      filter(filters);
    }
  }, [filters]);

  function giveMileageVal(mileageTo = "", mileageFrom = "") {
    if (mileageTo !== "") {
      filters.mileageFrom = "";
      return mileage.indexOf(mileageTo);
    }
    if (mileageFrom !== "") {
      filters.mileageTo = "";
      return mileage.indexOf(mileageFrom);
    }
    return 0;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        flexWrap: "wrap",
        flex: 0.07,
      }}
    >
      <Box sx={{ border: "1px solid #F4F4F4", padding: "10px" }}>
        <Typography fontSize={18} color={"#1f3823"}>
          Price
        </Typography>
        <Box display={"flex"} sx={{ gap: "10px", marginTop: "12px" }}>
          <form>
            <Typography color={"#969696"} fontWeight={"bold"}>
              Minimum
            </Typography>
            <TextField
              size="small"
              name="priceFrom"
              value={filters.priceFrom}
              onChange={handleChange}
              id="outlined-basic"
              variant="outlined"
              placeholder="$5,300"
            />
          </form>
          <form>
            <Typography color={"#969696"} fontWeight={"bold"}>
              Maximum
            </Typography>
            <TextField
              size="small"
              name="priceTo"
              value={filters.priceTo}
              onChange={handleChange}
              id="outlined-basic"
              variant="outlined"
              placeholder="$25,300"
            />
          </form>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #F4F4F4", padding: "10px" }}>
        <Typography fontSize={18} color={"#1f3823"}>
          Years
        </Typography>
        <Box display={"flex"} sx={{ gap: "10px", marginTop: "12px" }}>
          <form className="filter_form">
            <Typography color={"#969696"} fontWeight={"bold"}>
              Minimum
            </Typography>
            <Select
              defaultValue={0}
              value={
                filters.yearsFrom === ""
                  ? 0
                  : years().indexOf(filters.yearsFrom)
              }
              sx={{
                width: 120,
                height: 40,
              }}
              onChange={(e) =>
                setFilters((prev) => {
                  return { ...prev, yearsFrom: years()[e.target.value] };
                })
              }
            >
              {years().map((item, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </form>
          <form className="filter_form">
            <Typography color={"#969696"} fontWeight={"bold"}>
              Maximum
            </Typography>
            <Select
              defaultValue={0}
              value={
                filters.yearsTo === "" ? 0 : years().indexOf(filters.yearsTo)
              }
              sx={{
                width: 129,
                height: 40,
              }}
              onChange={(e) =>
                setFilters((prev) => {
                  return { ...prev, yearsTo: years()[e.target.value] };
                })
              }
            >
              {years().map((item, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </form>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #F4F4F4", padding: "10px" }}>
        <Typography fontSize={18} color={"#1f3823"}>
          Mileage
        </Typography>
        <Box display={"flex"} sx={{ gap: "10px", marginTop: "12px" }}>
          <form className="filter_form">
            <Select
              defaultValue={0}
              value={giveMileageVal(filters.mileageTo, filters.mileageFrom)}
              sx={{
                width: 258,
                height: 40,
              }}
              onChange={(e) =>
                setFilters((prev) => {
                  if (e.target.value === mileage.length) {
                    return { ...prev, mileageFrom: 200000, mileageTo: "" };
                  }
                  return {
                    ...prev,
                    mileageTo: mileage[e.target.value],
                    mileageFrom: "",
                  };
                })
              }
            >
              {mileage.map((item, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {item === "Any" ? "" : "Under"}{" "}
                    {item.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </MenuItem>
                );
              })}
              <MenuItem value={mileage.length}>Over 200,000</MenuItem>
            </Select>
          </form>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #F4F4F4", padding: "10px" }}>
        <Typography fontSize={18} color={"#1f3823"}>
          Active
        </Typography>
        <Box display={"flex"} sx={{ gap: "10px", marginTop: "12px" }}>
          <form className="filter_form">
            <Select
              defaultValue={0}
              value={
                filters.isActive === "" ? 0 : activeStatus.indexOf(filters?.isActive)
              }
              sx={{
                width: 258,
                height: 40,
              }}
              onChange={(e) =>
                setFilters((prev) => {
                  console.log("setting value onChange: " + JSON.stringify(e.target.value))
                  return {
                    ...prev,
                    isActive: activeStatus[e.target.value],
                  };
                })
              }
            >
              {activeStatus.map((item, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </form>
        </Box>
      </Box>
      <Button
        disableElevation
        sx={{
          bgcolor: "#87AC8D",
          color: "white",
          width: "160px",
          marginInline: "auto",
          fontWeight: "600",
          fontSize: "15px",
          "&:hover": {
            bgcolor: "#87AC8D",
            color: "white",
          },
        }}
        onClick={handleClear}
      >
        Clear filters
      </Button>
    </Box>
  );
};

export default Filter;
