import { login as loginEmailPassword } from "api/api";
import { useContext } from "react";
import AuthContext from "context/autho";
import jwt_decode from "jwt-decode";
// import { useMutation } from "react-query";
import { toast } from "react-toastify";

const TOKEN_KEY = "access_token";

export default function useAuthHook() {
  
  let { user, setUser, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext);

  const login = async (loginData) => {

    try {
      let data = await loginEmailPassword(loginData);

      setIsAuthenticated(true);


      for (let group of data?.groups){
        console.log("user part of group: " + group);
        //if (group?.shopName == "CheckDrv" || group?.shopName == "Garage") {
      }

      if(data?.groups?.length > 0){
        console.log("user shops are: " + JSON.stringify(data?.groups));

        setIsAuthenticated(true);

        if (data.accessToken) {
          localStorage.setItem(TOKEN_KEY, data.accessToken);
  
          setUser(jwt_decode(data.accessToken));
  
          setIsAuthenticated(true);
        }

        return data;
      }

      /*if (data.accessToken) {
        localStorage.setItem(TOKEN_KEY, data.accessToken);

        setUser(jwt_decode(data.accessToken));

        console.log("setting auth");
        setIsAuthenticated(true);
        console.log("auth set");

        return data;
      } else {*/
      toast.error("User could not be authenticated")
      setIsAuthenticated(false);
      return null
      //}
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    localStorage.removeItem(TOKEN_KEY);
    setIsAuthenticated(false);
  };

  //functions we're exposing
  return {
    isAuthenticated,
    login,
    logout,
    user,
  };
}
