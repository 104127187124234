import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import CreateIcon from "@mui/icons-material/Create";
import ArchiveIcon from "@mui/icons-material/Archive";

//components
import EditStockMakeFrom from "../StockMakeForm/index";
import ConfirmationBox from "../confirmationBox/index";

//generic componetns
import ToolTip from "components/GenericComponents/ToolTip/index";

//context
import StockMakeContext from "context/stockMakes";

import { archiveRecord } from "api/api";

const Main = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
  border: groove;
  position: relative;
  width: 100%;
  overflow: auto;
  background: white;
  height: 80vh;
`;

const Header = styled.div`
  height: 27px;
`;

const Name = styled.span`
  color: black;
  margin-left: 12px;
`;

const EditIconContainer = styled.div`
  float: right;
  margin-right: 5px;
`;

export default function Index({ stockMakeDataRefetch }) {

  //sets whether inputs / submit button are disabled
  const [disabled, setIsDisabled] = React.useState(true);

  //opens delete modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  let {
    editableProperties,
    changeIndex,
    setFirstEditableProperty,
  } = React.useContext(StockMakeContext);

  const handleDisabled = (value) => {
    setIsDisabled(value);
  };

  const archiveRecordIns = () => {
    archiveRecord().then((res) => {
      setFirstEditableProperty();
      changeIndex();
      stockMakeDataRefetch();
    });
  };
  
  return (
    <>
      <ConfirmationBox
        openById={deleteModal}
        onclose={() => setDeleteModal(false)}
        archiveRecord={archiveRecordIns}
        title={"Are you sure you want to Archive this StockMake?"}
      />
      <Main>
        <Header>
          <Name>Stock Make</Name>
            <EditIconContainer>
              <ToolTip title={`Archive this`}>
                <ArchiveIcon onClick={() => setDeleteModal(true)} />
              </ToolTip>
              <ToolTip title={`Update this`}>
                <CreateIcon onClick={() => handleDisabled(false)} />
              </ToolTip>
            </EditIconContainer>
            <EditStockMakeFrom
              data={editableProperties}
              disabled={disabled}
              btnText={"Update StockMake"}
              handleDisabled={handleDisabled}
              type="edit"
              onSubmit={() => {
                stockMakeDataRefetch();
                handleDisabled(true);
              }}
            />
        </Header>
        <Divider />
      </Main>
    </>
  );
}