import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";

import InspectionProvider from "./provider/inspectionProvider";
import StockMakesProvider from "./provider/stockMakesProvider";
import AuthProvider from "./provider/AuthProvider";
import AddListingProvider from "./provider/addListingProvider";
import GarageProvider from "./provider/garageProvider";
import ListingsProvider from "./provider/listingsProvider";
import UsersProvider from "./provider/usersProvider";
import PromptProvider from "./provider/promptProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
      <InspectionProvider>
      <StockMakesProvider>
        <AddListingProvider>
          <GarageProvider>
            <ListingsProvider>
              <UsersProvider>
              <PromptProvider>
                <App />
                <ToastContainer
                  position="top-right"
                  autoClose={20000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </PromptProvider>
              </UsersProvider>
            </ListingsProvider>
          </GarageProvider>
        </AddListingProvider>
      </StockMakesProvider>
      </InspectionProvider>
      </AuthProvider>
    </QueryClientProvider>
    {/* </QueryClientProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
