import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import RowRadioButtonsGroup from "components/GenericComponents/RadioGroup/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { useMutation } from "react-query";
import { createItem, updateItem } from "api/api";
import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";
import InspectionContext from "context/inspection";

const ButtonContainer = styled.div`
text-align: center;
 }
`;
const validationSchema = Yup.object({
  itemName: Yup.string().required("Category Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  categoryId,
  disabled = false,
  handleDisabled,
  type = "add",
  btnText,
}) {
  let { setEditableProperties } = React.useContext(InspectionContext);

  const initialValues = {
    itemName: data?.itemName || "",
    videoRequired: data?.videoRequired || "Yes",
    categoryOptional: data?.categoryOptional || false,
    photoRequired: data?.photoRequired || "Yes",
    description: data?.description || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateItemMutate(
          { ...values, itemId: data._id },
          {
            onSuccess: (data) => {
              setEditableProperties(data.item);
              onSubmit();
            },
          }
        );
      } else {
        createItemMutate(
          { ...values, categoryId },
          {
            onSuccess: () => {
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createItemMutate } = useMutation(createItem);
  const { mutate: updateItemMutate } = useMutation(updateItem);

  const handleRadioChange = (key, value) => {
    formik.setFieldValue(key, value);
  };
  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "15px" }}>
          <Grid item xs={12}>
            <Input
              label="Item Name"
              required
              name="itemName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.itemName}
              error={
                formik.touched.itemName && formik.errors.itemName
                  ? formik.errors.itemName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <RowRadioButtonsGroup
              title="Video Required"
              name="videoRequired"
              onChange={formik.handleChange}
              value={formik.values.videoRequired}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <RowRadioButtonsGroup
              title="Photo Required"
              name="photoRequired"
              onChange={formik.handleChange}
              value={formik.values.photoRequired}
              handleRadioChange={handleRadioChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineTextFields
              onChange={formik.handleChange}
              label={"Description"}
              name="description"
              value={formik.values.description}
              disabled={disabled}
              size="1%"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Item"}
                type="submit"
                display={disabled ? "none" : ""}
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
