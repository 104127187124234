import React from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useEffect, useState } from "react";
import DetailsHeader from "./components/DetailsHeader";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import FactoryIcon from "@mui/icons-material/Factory";
import TableCell from "@mui/material/TableCell";
// import Offeritems from "../Offers/components/OfferItems";
// import { , createOffer, tempFunction } from "api/api";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import ListingsContext from "context/listings";
import { get_image_urls } from "../MyGarage/CarCard";
import { get_image_urls_multi_section } from "./ListingDetailsWrapper";
import {
  Close,
  Colorize,
  ExpandMore,
  Fingerprint,
  FormatColorFill,
  LocalGasStation,
  LocationOn,
  Person2,
  Settings,
  ThreeP,
  TwoWheeler,
  ViewInAr,
} from "@mui/icons-material";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "./style.css";

import fileSaver from "file-saver";

import { downloadZipImages, createOffer } from "api/api";
import useAuthHook from "../../Hooks/useAuthHook";

const CarDetails = ({ listing }) => {
  var data = null;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: "center",
    maxWidth: "73%",
  };

  const accordionData = [
    {
      title: " Vehicle Repair Coverage - (coming soon)",
      content: "Coming soon...",
      img: "/car.svg",
    },
    {
      title: "Tire 6 Wheel Repair - (coming soon)",
      content: "Coming soon...",
      img: "/tire.svg",
    },
  ];
  let { currentSelectedListing } = React.useContext(ListingsContext);

  const { user, isAuthenticated } = useAuthHook();

  //offer creation
  /*
    vin: { type: String, default: null },
    listingId: { type: String, default: null },
    listingUserId: { type: String, default: null },
    offeringUserId: { type: String, default: null },
    offerPrice: { type: Number, default: null },
    dateOffered:  { type: String, default: null },
    expirationDate:  { type: String, default: null },
    listedPrice: { type: Number, default: null },
    accepted: { type: Boolean, default: false },
    isActive:  { type: String, default: false },
  */
  const validationSchema = Yup.object({
    vin: Yup.string().required("vin is required"),
    listingId: Yup.string().required("listingId is required"),
    offeringUserId: Yup.string().required("offeringUserId is required"),
    listingUserId: Yup.string().required("listingUserId is required"),
    offerPrice: Yup.string().required("offerPrice is required"),
  });

  const initialValues = {
    vin: currentSelectedListing?.vin || "",
    listingId: currentSelectedListing?._id || "",
    offeringUserId: user?._id || "",
    listingUserId: currentSelectedListing?.userId || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      console.log("form submitted values: " + JSON.stringify(values));
      if (data) {
        console.log("data", data);
        createOfferMutate(
          { ...values },
          {
            onSuccess: (data) => {
              //setPropertyName("offer");
              //changeIndex();
              //setEditableProperties(data.createdShops);
              //onSubmit();
              resetForm();
              setOpenone(false);
            },
          }
        );
      }
    },
  });

  const { mutate: createOfferMutate } = useMutation(createOffer);

  const imageUrls = get_image_urls_multi_section(
    currentSelectedListing?.inspection
  );
  const headerImageUrls = get_image_urls(currentSelectedListing?.inspection);

  const imgs = headerImageUrls.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const exterior = imageUrls?.Exterior?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const interior = imageUrls?.Interior?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const frame = imageUrls?.Frame?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const features = imageUrls?.Features?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const tires = imageUrls?.Tires?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const wheels = imageUrls?.Wheels?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const engine = imageUrls?.Engine?.map((item) => {
    return { original: item[0], thumbnail: item[0] };
  });
  const location = useLocation();
  const [vehiclePrice, setVehiclePrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loanPeriod, setLoanPeriod] = useState(1);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [modalimg, setModalimg] = useState([]);
  const [modaltitle, setModaltitle] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [offersData, setOffersData] = useState({
    offerPrice: "",
    expirationDate: "",
  });

  const [openone, setOpenone] = React.useState(false);

  const handleOpenone = () => {
    setOpenone(true);
  };
  const handleCloseone = () => {
    setOpenone(false);
  };

  const handleDownloadClick = (fileUrl) => {
    // Create a temporary anchor element
    const downloadLink = document.createElement("a");

    // Set the href and download attributes
    downloadLink.href = fileUrl;
    downloadLink.download = "downloaded-file.pdf";

    // Append the link to the document
    document.body.appendChild(downloadLink);

    // Trigger a click on the link to start the download
    downloadLink.click();

    // Remove the link from the document after the download
    document.body.removeChild(downloadLink);
  };
  const calculateMonthlyPayment = () => {
    const r = interestRate / (12 * 100);
    const n = loanPeriod * 12;
    const principal = vehiclePrice - downPayment;

    const monthlyPayment =
      (principal * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    if (monthlyPayment >= 0 && monthlyPayment) {
      setMonthlyPayment(monthlyPayment);
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleDownloadImages = async () => {
    if (currentSelectedListing?.vin) {
      console.log("getting images for vin: " + currentSelectedListing?.vin);

      //let response = await downloadZipImages(currentSelectedListing?.vin)
      await downloadZipImages(currentSelectedListing?.vin).then((data) => {
        let blob = new Blob([data], { type: "application/zip" });
        let vehicleType =
          currentSelectedListing?.inspection?.vehicleInfo?.vehicleType;
        let fileName =
          "CheckDrivePhotos-" +
          vehicleType?.year +
          "-" +
          vehicleType?.vehicleModel +
          "-" +
          vehicleType?.vehicleMake +
          ".zip";
        fileSaver.saveAs(blob, `${fileName}`);
      });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    calculateMonthlyPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePrice, downPayment, interestRate, loanPeriod]);

  let mainImage = "";
  if (headerImageUrls.length > 0) {
    mainImage = headerImageUrls[0][0];
  }
  const isLargeScreen = useMediaQuery("(min-width: 1199px)");
  const handleModal = (feature) => {
    switch (feature) {
      case "Interior":
        setModaltitle("Interior");
        setModalimg(interior);
        break;
      case "Exterior":
        setModaltitle("Exterior");
        setModalimg(exterior);
        break;
      case "Tires":
        setModaltitle("Tires");
        setModalimg(tires);
        break;
      case "Frame":
        setModaltitle("Frame");
        setModalimg(frame);
        break;
      case "Wheels":
        setModaltitle("Wheels");
        setModalimg(wheels);
        break;
      case "Features":
        setModaltitle("Features");
        setModalimg(features);
        break;
      case "Engine":
        setModaltitle("Engine");
        setModalimg(engine);
        break;
      default:
        setModaltitle("Photos");
        setModalimg(imgs);
        break;
    }
    handleOpen();
  };

  const handleChangeOfferData = (e) => {
    console.log("eventtttttttt", e.target);
    const { name, value } = e.target;
    setOffersData(() => {
      return {
        ...offersData,
        [name]: value,
      };
    });
  };

  const submitOffer = async () => {
    initialValues.offerPrice = offersData.offerPrice;
    initialValues.expirationDate = offersData.expirationDate;
    try {
      const y = await createOffer(initialValues);
      if (y.message === "Offer Created Successfully") {
        setOpenone(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <DetailsHeader
        listing={currentSelectedListing}
        from={location.state.path}
      />
      <Modal
        open={openone}
        onClose={handleCloseone}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="offer-modal">
        <Box sx={{ ...style, width: 600 }}>
          <form onSubmit={formik.handleSubmit}>
            <img
              src="/icons8-close.svg"
              alt="close"
              className="close-icon"
              onClick={handleCloseone}
            />
            <img src="/logo.svg" alt="logo" />
            <div className="profile-section">
              <img
                src="https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2264922221.jpg"
                alt="profile"
                className="profile-img"
              />
              <span>Abcd Auto Dealer</span>
              <span>Denver, CO</span>
            </div>
            <div className="field-section">
              <div className="field">
                <span>Amount:</span>
                <input
                  label="Amount: "
                  required
                  name="offerPrice"
                  onChange={handleChangeOfferData}
                />
              </div>

              <div className="field">
                <span>Offer Expires:</span>
                <input
                  type="date"
                  onChange={handleChangeOfferData}
                  name="expirationDate"
                />
              </div>
            </div>
            <div className="offer-content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et. .
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <Button
              className="submitoffer-btn"
              type="submit"
              children="Submit Offer"
              onClick={() => submitOffer()}
            />
          </form>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            backgroundColor: "black",
            width: "100%",
            height: "100vh",
            position: "relative",
          }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              color: "white",
              left: 0,
              cursor: "pointer",
              zIndex: "50",
            }}>
            <Close sx={{ cursor: "pointer" }} />
          </IconButton>
          <Box
            sx={{
              backgroundColor: "RGB(9,8,8,0.3)",
              width: "60%",
              marginInline: "auto",
              transform: "translateX(-50%)",
            }}
            left="50%"
            position={"fixed"}
            textAlign={"center"}
            zIndex={2}>
            <Typography
              color="white"
              fontSize={24}
              sx={{ width: "100%", opacity: 1 }}>
              {modaltitle}
            </Typography>
          </Box>
          <ImageGallery
            items={modalimg}
            showPlayButton={false}
            infinite
            showFullscreenButton={true}
          />
          ;
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: isAuthenticated ? "60px" : "10px",
          width: { xs: 320, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
          flexWrap: { xs: "wrap", md: "wrap", lg: "nowrap" },
        }}>
        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", md: "100%", lg: "75%" },
            paddingRight: { xs: "0", s: "0", md: "00px", lg: "20px" },
          }}>
          {mainImage === "" ? (
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              {" "}
              <CircularProgress color="success" />{" "}
            </Box>
          ) : (
            <img
              src={mainImage}
              onClick={handleModal}
              style={{
                width: "100%",
                height: {
                  xs: "300px",
                  sm: "300px",
                  md: "500px",
                  cursor: "pointer",
                },
              }}
              alt="img"
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: { xs: "100%", md: "100%", lg: "25%" },
          }}>
          <ImageList
            sx={{
              maxWidth: "100%",
              height: "auto",
              flexWrap: "nowrap",
              marginTop: 0,
            }}
            rowHeight={{ xs: 80, md: 130, lg: 130 }}
            cols={isLargeScreen ? 2 : 4}>
            {headerImageUrls.slice(0, 7).map(([url, name], i) => (
              <ImageListItem
                key={url}
                onClick={handleModal}
                sx={{ cursor: "pointer" }}>
                <img
                  srcSet={`${url}`}
                  src={`${url}`}
                  alt={name}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </ImageListItem>
            ))}
            <Box
              backgroundColor={"black"}
              color={"white"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => handleModal("")}>
              <Typography fontSize={{ xs: "10px", sm: "12px" }}>
                VIEW ALL PHOTOS
              </Typography>
            </Box>
          </ImageList>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: "12px",
          width: { xs: 320, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
        }}>
        <Button
          disableRipple
          sx={{
            color: "white",
            ":hover": {
              bgcolor: "transparent",
              border: "1px solid #87AC8D",
              color: "#87AC8D",
            },
            bgcolor: "#87AC8D",
            width: windowDimensions.width > 1528 ? "484px" : "23em",
          }}
          onClick={handleDownloadImages}>
          DOWNLOAD FEATURED LISTING PHOTOS{" "}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
          width: { xs: 320, sm: 600, md: 1000, xl: 1500 },
          padding: "0.5em",
          marginInline: "auto",
          marginTop: "1em",
        }}>
        <Box sx={{ flex: { md: 1 } }}>
          <Typography
            fontSize={36}
            fontWeight={"bold"}
            sx={{ textTransform: "uppercase" }}>
            {currentSelectedListing?.inspection?.vehicleInfo?.vehicleType?.year}{" "}
            {
              currentSelectedListing?.inspection?.vehicleInfo?.vehicleType
                ?.vehicleMake
            }{" "}
            {
              currentSelectedListing?.inspection?.vehicleInfo?.vehicleType
                ?.vehicleModel
            }
          </Typography>
          <Typography fontSize={32} fontWeight={"bold"} color={"#87AC8D"}>
            {listing && "$"}
            {currentSelectedListing?.sellerDesiredPrice
              ?.toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={"bold"}
            sx={{ marginTop: "1em" }}>
            VEHICLE STORY
          </Typography>
          <Typography fontSize={16} sx={{ marginTop: "1em" }}>
            {currentSelectedListing?.description}
          </Typography>
          {Object.entries(imageUrls).map(([key, value]) => (
            <Box>
              <Typography sx={{ mt: 2 }} fontSize={22} fontWeight={"bold"}>
                {key} ( {value.length} )
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  cursor: "pointer",
                }}
                onClick={() => handleModal(key)}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    marginTop: "2rem",
                  }}>
                  {value?.map(([url, categoryName, categoryWeight]) => (
                    <Box
                      key={url}
                      style={{
                        flexBasis: "calc(25% - 10px)",
                        boxSizing: "border-box",
                        marginRight: "1rem",
                      }}>
                      <img
                        src={url}
                        alt={categoryName}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
          <Typography
            fontWeight={"bold"}
            fontSize={22}
            sx={{ marginTop: "2em" }}>
            Standard features:
          </Typography>
          <List
            sx={{
              columnCount: { xs: 1, sm: 2, md: 4 },
              gap: "3em",
              listStyleType: "disc",
            }}>
            {currentSelectedListing?.inspection?.vehicleInfo?.vehicleVINInfo?.userSpecifiedFeatures.map(
              (item, i) => (
                <li key={i} style={{ marginBottom: "12px", minWidth: "30%" }}>
                  {item}
                </li>
              )
            )}
          </List>
        </Box>
        <Box sx={{ flex: { md: 0.5 }, width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F4F4F4",
              paddingBottom: "8px",
            }}>
            <Typography
              fontSize={14}
              fontWeight={"bold"}
              sx={{ marginLeft: "10px", paddingTop: "10px", color: "#262424" }}>
              {" "}
              CAR DETAILS
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <CalendarMonthIcon fontSize="16px" />
              <span>
                Year:{" "}
                {
                  currentSelectedListing?.inspection?.vehicleInfo?.vehicleType
                    ?.year
                }
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <FactoryIcon fontSize="16px" />
              <span>
                Make:{" "}
                {
                  currentSelectedListing?.inspection?.vehicleInfo?.vehicleType
                    ?.vehicleMake
                }
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <TwoWheeler fontSize="16px" />
              <span>
                Engine:{" "}
                <span style={{ fontSize: "12px" }}>
                  {
                    currentSelectedListing?.inspection?.vehicleVINInfo
                      ?.factorySpecs?.attributes?.standard?.engine
                  }
                </span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <ViewInAr fontSize="16px" />
              <span>
                Model:{" "}
                <span>
                  {
                    currentSelectedListing?.inspection?.vehicleInfo?.vehicleType
                      ?.vehicleModel
                  }
                </span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <Settings fontSize="16px" />
              <span>
                Transmission:{" "}
                <span>
                  {
                    currentSelectedListing?.inspection?.vehicleVINInfo
                      ?.factorySpecs?.attributes?.standard?.transmission
                  }
                </span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <LocalGasStation fontSize="16px" />
              <span>
                Mileage:{" "}
                <span>
                  {currentSelectedListing?.mileage
                    ?.toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <DirectionsCarIcon fontSize="16px" />
              <span>
                Body Style: <span>SEDAN 4-DR</span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <Fingerprint fontSize="16px" />
              <span>
                VIN: <span>{currentSelectedListing?.vin.toUpperCase()}</span>
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <FormatColorFill fontSize="16px" />
              <span>
                Exterior Color:{" "}
                {currentSelectedListing?.garageVehicleInfo?.vehicleInfo?.color}
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <Colorize fontSize="16px" />
              <span>
                Interior Color:{" "}
                {
                  currentSelectedListing?.garageVehicleInfo?.vehicleInfo
                    ?.interiorColor
                }
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "10px",
                gap: "4px",
              }}>
              <Person2 fontSize="16px" />
              <span>Title Status: </span>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              paddingBottom: "5px",
              height: { sm: "100px", md: "100px" },
              marginTop: "1em",
              backgroundColor: "#F4F4F4",
            }}>
            <Typography
              fontSize={14}
              fontWeight={"bold"}
              sx={{ marginLeft: "10px", paddingTop: "10px", color: "#262424" }}>
              SELLER INFO
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <LocationOn fontSize="16px" />
              <span>
                Location:{" "}
                {currentSelectedListing?.vehiclePhysicalLocation?.city},{" "}
                {currentSelectedListing?.vehiclePhysicalLocation?.state}
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <ThreeP fontSize="16px" />
              <span>Seller Type: Private Party</span>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: { sm: "360px", md: "360px" },
              marginTop: "1em",
              paddingBottom: "10px",
              backgroundColor: "#F4F4F4",
            }}>
            <Typography
              fontSize={14}
              fontWeight={"bold"}
              sx={{ marginLeft: "10px", paddingTop: "10px", color: "#262424" }}>
              ESTIMATED COST
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <Typography fontSize={"14px"}>Delivery Price</Typography>
              <Typography fontSize={"12px"}>(Coming Soon)</Typography>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <Typography fontSize={"14px"}>Vehicle Price</Typography>
              <span>
                $
                {currentSelectedListing?.sellerDesiredPrice
                  ?.toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <Typography fontSize={"14px"}>Shipping Details</Typography>
              <Typography fontSize={"12px"}>
                *Calculated on shipping address
              </Typography>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <Typography fontSize={"14px"}>Taxes</Typography>
              <Typography fontSize={"12px"}>
                *Calculated on buyer location
              </Typography>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "5px",
                gap: "4px",
              }}>
              <Typography fontSize={"14px"}>Total:</Typography>
              <span>
                $
                {currentSelectedListing?.sellerDesiredPrice
                  ?.toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </span>
            </Box>
            <Divider
              sx={{ width: "90%", marginInline: "auto", marginTop: "4px" }}
            />
            <Box sx={{ marginInline: "12px" }}>
              <a
                href="https://www.lendingtree.com/lp/auto/loans.html?esourceid=6284676&cproduct=autopurch&clicktype=headline-&cchannel=sem&cname=autopurch.nb&csource=google&disc=1,57,58,59,99&mtaid=DD28D&campaign_id=20134740880&ad_group_id=148671185946&keyword_id=kwd-20243181&ad_id=658489632604&ad_click_type=headline&ccontent=cname-Auto_Purchase_G:EX:OO&cterm=c&ppckw=auto%20finance_e&matchtype=e&ctype=g&adid=658489632604&cmethod=9028772&ccreative=&ccampaign=Auto_Purchase_G:EX:OO&cgroup=Purchase_General_Finance_EX:OO&ad_group_id=148671185946&ad_id=658489632604&keyword=auto%20finance&keyword_id=kwd-20243181&keyword_match_type=e&ad_extension_id=&placement_name=&ad_position=&network_name=g&experiment_id=&campaign_id=20134740880&s_kwcid=AL!6324!3!658489632604!e!!g!!auto%20finance&cq_src=GOOGLE&cq_cmp=71700000110438389&cq_con=Purchase_General_Finance_EX:OO&cq_term=auto+finance&gad_source=1&gclid=Cj0KCQiAw6yuBhDrARIsACf94RWQkmQGk6Lr07inwWFerYC_duZGp3TeGAcH_2UACI6KAiJE0usaSFEaAgGJEALw_wcB&gclsrc=aw.ds&sessionid=40a0c165-8896-47cc-8a03-2dba465e38b4&mta=1"
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  disableRipple
                  sx={{
                    ":hover": {
                      bgcolor: "transparent",
                      border: "1px solid #87AC8D",
                      color: "#87AC8D",
                    },
                    color: "white",
                    bgcolor: "#87AC8D",
                    width: "100%",
                    marginTop: "1em",
                  }}>
                  GET PRE-QUALIFIED
                </Button>
              </a>
              <a
                href="https://www.thezebra.com/rates-compare/?addisttype=g&adid=610286302546&c3api=4761,57057989404,kwd-11638091801&channelid=nr5711&channelname=google-adwords-branded&g_acctid=335-577-4761&g_adgroupid=57057989404&g_adid=610286302546&g_adtype=search&g_campaign=zebra+%7c+g+%7c+b+%7c+trademark+%7c+core+%7c+exact&g_campaignid=1399105423&g_keyword=zebra%20insurance&g_keywordid=kwd-11638091801&g_network=g&keyword=zebra%20insurance&subid3=cj0kcqiaw6yubhdrarisacf94rxj_t_n8hoa7ynluvso-hf4mao8kgz6yfrwqop8ayy8rdnguspqnquaaivqealw_wcb&subid4=9028772&utm_adgroup=57057989404&utm_campaign=1399105423&utm_medium=sem&utm_source=google&gclsrc=aw.ds&gclid=Cj0KCQiAw6yuBhDrARIsACf94RXJ_T_n8HOA7YNluVSO-hF4mAO8KGZ6YfrwQOp8ayY8RDnGUSPqnQUaAivQEALw_wcB"
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  disableRipple
                  sx={{
                    ":hover": {
                      bgcolor: "transparent",
                      border: "1px solid #87AC8D",
                      color: "#87AC8D",
                    },
                    color: "white",
                    bgcolor: "#87AC8D",
                    width: "100%",
                    marginTop: "1em",
                  }}>
                  NEED INSURANCE CLICK HERE
                </Button>
              </a>
              <Button
                disableRipple
                onClick={handleOpenone}
                sx={{
                  ":hover": {
                    bgcolor: "transparent",
                    border: "1px solid #87AC8D",
                    color: "#87AC8D",
                  },
                  color: "white",
                  bgcolor: "#87AC8D",
                  width: "100%",
                  marginTop: "1em",
                }}>
                Make offer
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: { sm: "100px", md: "100px" },
              marginTop: "1em",
              backgroundColor: "	#F5F5F5",
            }}>
            <Typography
              fontSize={14}
              fontWeight={"bold"}
              align="center"
              sx={{
                marginLeft: "10px",
                paddingTop: "10px",
                textDecoration: "underline",
                color: "#262424",
              }}>
              LOAN CALCULATOR
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              columns={{ xs: 2, sm: 2, md: 2 }}
              justifyContent={"center"}
              sx={{ marginTop: "4px" }}>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    placeholder="Vehicle Price"
                    value={vehiclePrice === 0 ? "" : vehiclePrice}
                    onChange={(e) => setVehiclePrice(e.target.value)}
                    style={{
                      width: "110px",
                      height: "47px",
                      border: "0.2px solid #F0F8FF",
                    }}
                  />
                  <Box
                    bgcolor={"#F0F8FF"}
                    height={47}
                    width={22}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1px",
                      alignItems: "center",
                    }}>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"semibold"}
                      sx={{ color: "black   " }}>
                      $
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    placeholder="Down Payment"
                    value={downPayment === 0 ? "" : downPayment}
                    onChange={(e) => setDownPayment(e.target.value)}
                    style={{
                      width: "110px",
                      height: "47px",
                      border: "0.2px solid #F0F8FF",
                    }}
                  />
                  <Box
                    bgcolor={"#F0F8FF"}
                    height={47}
                    width={22}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1px",
                      alignItems: "center",
                    }}>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"semibold"}
                      sx={{ color: "black" }}>
                      $
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: { sm: "180px", md: "180px" },
              marginTop: "1em",
              backgroundColor: "	#F5F5F5",
              paddingBottom: "5px",
            }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              columns={{ xs: 2, sm: 2, md: 2 }}
              justifyContent={"center"}>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    placeholder="Interest Rate"
                    value={interestRate === 0 ? "" : interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                    style={{
                      width: "110px",
                      height: "47px",
                      border: "0.2px solid #F0F8FF",
                    }}
                  />
                  <Box
                    bgcolor={"#F0F8FF"}
                    height={47}
                    width={22}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1px",
                      alignItems: "center",
                    }}>
                    <Typography
                      fontSize={"12px"}
                      fontWeight={"semibold"}
                      sx={{ color: "black" }}>
                      %
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    placeholder="Loan Period"
                    value={loanPeriod}
                    onChange={(e) => setLoanPeriod(e.target.value)}
                    style={{
                      width: "110px",
                      height: "47px",
                      border: "0.2px solid #F0F8FF",
                    }}
                  />
                  <Box
                    bgcolor={"#F0F8FF"}
                    height={47}
                    width={28}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1px",
                      alignItems: "center",
                    }}>
                    <Typography
                      fontSize={"10px"}
                      fontWeight={"semibold"}
                      sx={{ color: "black" }}>
                      Years
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1em",
              }}>
              <Box
                bgcolor={"#F0F8FF"}
                height={46}
                sx={{ display: "flex", padding: "1px", alignItems: "center" }}>
                <Typography
                  fontSize={"10px"}
                  fontWeight={"bold"}
                  sx={{ paddingInline: "6px", color: "black" }}>
                  Monthly Payment
                </Typography>
              </Box>
              <input
                placeholder=""
                value={"$" + monthlyPayment.toFixed(2)}
                style={{
                  width: "64.5%",
                  height: "46px",
                  backgroundColor: "white",
                  border: "none",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: { xs: 310, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
          cursor: "pointer",
        }}
        onClick={() =>
          handleDownloadClick(currentSelectedListing?.bumperReportURL)
        }>
        <img
          src="/report.png"
          style={{ objectFit: "fill", aspectRatio: "4/3", width: "100%" }}
          alt="report"
        />
      </Box>

      {/* <Box
        sx={{
          width: { xs: 310, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
          padding: "12px",
          marginTop: "1em",
          marginBottom: "1em",
        }}
        bgcolor={"#F4F4F4"}>
        <Typography fontSize={24} fontWeight={"bold"}>
          Add Care & Protection Plans
        </Typography>
        <Accordion sx={{ margin: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMore color="#87AC8D" />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography
              fontSize={14}
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <img
                src="/car.svg"
                style={{ width: "40px", height: "40px" }}
                alt="car-svg"
              />
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Vehicle Repair Coverage
              </span>{" "}
              - (Coming Soon)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Coming soon...</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMore color="#87AC8D" />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography
              fontSize={14}
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <img
                src="/tire.svg"
                style={{ width: "40px", height: "40px" }}
                alt="tire-svg"
              />
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Tire & Wheel Repair{" "}
              </span>{" "}
              - (Coming Soon)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Coming soon...</Typography>
          </AccordionDetails>
        </Accordion>
      </Box> */}

      <Box
        sx={{
          width: { xs: 310, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
          padding: "12px",
          marginTop: "1em",
          marginBottom: "1em",
        }}
        bgcolor={"#F4F4F4"}>
        <Typography fontSize={24} fontWeight={"bold"}>
          Add Care & Protection Plans
        </Typography>
        <div className="accordion-items-list">
          {accordionData.map((item, index) => (
            <div
              key={index}
              className={`accordion-item  ${
                index != accordionData.length - 1 ? "last-item" : ""
              }`}>
              <button
                className={`accordion ${activeIndex === index ? "active" : ""}`}
                onClick={() => toggleAccordion(index)}>
                <div className="btn-content">
                  <div className="image-title-content">
                    <img
                      src={item.img}
                      style={{ width: "40px", height: "40px" }}
                      alt="car-svg"
                    />
                    <span style={{ color: "black", fontWeight: "bolder" }}>
                      {item.title}
                    </span>
                  </div>

                  <span className="arrow">
                    {/* {activeIndex === index ? "▲" : "▼"} */}
                    {activeIndex === index ? (
                      <img src="/uparrow.png" className="up-arrow"></img>
                    ) : (
                      <img src="/downarrow.png" className="down-arrow"></img>
                    )}
                  </span>
                </div>
                {activeIndex === index && (
                  <div className="panel">
                    <p>{item.content}</p>
                  </div>
                )}
              </button>
            </div>
          ))}
        </div>
      </Box>

      <h1 style={{ textAlign: "center" }}>How Checkdrive Works</h1>
      {/* <Box sx={{width:{xs:390,sm:600,md:1000,xl:1500},marginInline:"auto",padding:"12px",marginTop:"1em",marginBottom:"1em"}}>
            <video width="100%" height="400" controls>
                <source src="https://www.youtube.com/watch?v=TNnqFZ6VBx8" type="video/mp4"/>
            </video>
          </Box> */}
      {/* <Box
        sx={{
          width: { xs: 390, sm: 600, md: 1000, xl: 1500 },
          marginInline: "auto",
          padding: "12px",
          marginTop: "1em",
          marginBottom: "1em",
        }}>
        <iframe
          title="CheckDrive Video"
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/TNnqFZ6VBx8"></iframe>
      </Box> */}
    </div>
  );
};

export default CarDetails;
