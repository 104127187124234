import "./App.css";
import Router from "../src/routes";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";




// Use your config values here.
initializeApp({
  apiKey: "AIzaSyDZaZc9yCUxfZFND09ClKdGr8GsfmiS-lw",
  authDomain: "check-drive-153d2.firebaseapp.com",
  projectId: "check-drive-153d2",
  storageBucket: "check-drive-153d2.appspot.com",
  messagingSenderId: "92665179468",
  appId: "1:92665179468:web:c0cd2bc254267b01ad73e9",
  measurementId: "G-V4D8M6DB5X",
});

function App() {
  return (
    <div>

      <BrowserRouter >  
        <Router />
      </BrowserRouter>

    </div>
  );
}

export default App;