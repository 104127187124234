import React from "react";

import OfferHeader from "./OffersHeader";
import "../../../App.css";

const SpecificOffer = (offer) => {
  const array = [
    {
      label: "Name",
      value: "Dealership 1 Audi",
    },
    {
      label: "Offer Date",
      value: "Dec 1,2023",
    },
    {
      label: "Expires",
      value: "Jan, 1 2024",
    },
    {
      label: "Loacrtion",
      value: "Denver, Co",
    },
  ];

  const array1 = [
    {
      label: "Payment Type",
      value: "Dealer check",
    },
    {
      label: "Address",
      value: "",
    },
    {
      label: "Hours",
      value: "",
    },
    {
      label: "Delkivery Method",
      value: "Drop Off",
    },
  ];
  return (
    <>
      <OfferHeader />

      <div className="specific-car-section">
        <div className="specific-car-offer-img-section">
          <div className="specific-car-offer-left-side-content">
            <img
              src="https://www.primemotorz.com/imagetag/1472/4/l/Used-2015-Volkswagen-Passat-20L-TDI-SEL-PREMIUM-4DR-SEDAN-20L-TDI-SEL-Premium-1688680132.jpg"
              alt="car-offer"
            />
            <h1>2015 VW Passat Offer</h1>
          </div>
          <div className="specific-order-price">
            <h1>$9,950</h1>
          </div>
        </div>
        <div className="specific-offer-content-section">
          <div className="specific-card-offer-content">
            <div className="specific-car-section-1">
              {array?.map((item) => {
                return (
                  <div className="specific-car-section-1-CONTENT">
                    <p>{item?.label}</p>
                    <h4>{item?.value}</h4>
                  </div>
                );
              })}
            </div>
            <div className="specific-car-section-1">
              {array1?.map((item) => {
                return (
                  <div className="specific-car-section-1-CONTENT">
                    <p>{item?.label}</p>
                    <h4>{item?.value}</h4>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="specific-card-btn-section">
            <button className="archieve-btn">Archieve Offer</button>
            <button className="accept-btn">Accept Offer</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificOffer;
