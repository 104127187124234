import React from "react";
import Grid from "@mui/material/Grid";
import PageTitle from "../../components/GenericComponents/PageTitle/index";
import styled from "styled-components";
import Button from "../../components/GenericComponents/Button/index";
import { useNavigate, useLocation } from "react-router";
import { useQuery } from "react-query";
import { getChatGptPrompts } from "../../api/api";
import Modal from "../../components/GenericComponents/Modal/index";
import Form from "../../components/AIPrompt/PromptForm/index";
import EditPrompt from "../../components/AIPrompt/EditPromptProperties/index.js";
import PromptContext from "../../context/prompt";
const List = React.lazy(() => import("../../components/AIPrompt/PromptList/index.js"));

const Properties = React.lazy(() =>
  import("../../components/AIPrompt/EditPromptProperties/index")
);
const ButtonContainer = styled.div`
margin-left: 6px;
margin-bottom: 8px;
margin-top: -38px;
float: right;
}
`;
const Title = styled.div`
  text-align: center;
  font-size: larger;
`;
export default function Index() {
  const [modal, setModal] = React.useState(false);
  const [selectedPrompt, setSelectedPrompt] = React.useState(null);
  let { currentSelectedPrompt } = React.useContext(PromptContext);
  const chatGptPrompts = useQuery("getChatGptPrompts", getChatGptPrompts, {});

  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
  };

  return (
    <>
      <React.Suspense>
        <PageTitle tittle="ChatGPT Prompt" />
        <Modal
          open={modal}
          onclose={() => {
            setModal(false);
          }}
          title={
            <>
              <span> Add Prompt</span>
            </>
          }
        >
          <Form
            onSubmit={() => {
                chatGptPrompts.refetch();
              setModal(false);
            }}
          />
        </Modal>
        <ButtonContainer>
          <Button
            children={"Add Prompt"}
            borderColor="#42B94C"
            onClick={() => setModal(true)}
          />
        </ButtonContainer>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Title>Prompt List</Title>
            <List
              promptData={chatGptPrompts?.data}
              handleSelectInspection={handleSelectPrompt}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Properties</Title>
            <Properties
              selectedPrompt={
                currentSelectedPrompt
                  ? currentSelectedPrompt
                  : chatGptPrompts?.data?.[chatGptPrompts?.length > 1 ? 1 : 0]?.items?.[0]
              }
              promptDataRefetch={chatGptPrompts.refetch}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Edit Properties</Title>
            <EditPrompt
              name="inspection"
              promptDataRefetch={chatGptPrompts.refetch}
            />
          </Grid>
        </Grid>
      </React.Suspense>
    </>
  );
}
