import * as React from "react";
import { useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core";

import OfferItem from "./OfferItem";

// import Paper from "@mui/material/Paper";
// import { Button, Typography } from "@mui/material";
// import TableCell from "@mui/material/TableCell";

function createData(
  name,
  subname,
  date,
  year,
  expireDate,
  expireYear,
  location,
  price
) {
  return { name, subname, date, year, expireDate, expireYear, location, price };
}
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);
const rows = [
  createData(
    "Car-Name",
    "Car-type",
    "Dec. 1",
    "2023",
    "Jan. 1",
    "2024",
    "Denver, CO",
    "9950"
  ),
];

export default function OfferItems({ offers }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    console.log("1233");
    navigate("/offers/1");
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, border: "none" }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              borderBottom: "1px solid #D6D6D6",
              color: "#969696",
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
            }}>
            <TableCell align="center">Vehicle</TableCell>
            <TableCell align="left">Offer Date</TableCell>
            <TableCell align="left">Expires</TableCell>
            <TableCell align="left">Location</TableCell>
            <TableCell align="left">Offer</TableCell>
            <TableCell align="left"> </TableCell>
          </TableRow>
        </TableHead>
       <TableBody>
          {offers?.map((offer) => (
            <OfferItem offer={offer} />
          ))}
        </TableBody> 
      </Table>
    </TableContainer>
  );
}
