import { Box, Button } from "@mui/material";
import React from "react";
import Profile from "./../AccountSettings/Profile";
import Security from "./../AccountSettings/Security";
import SettingHeader from "./components/SettingHeader";
import Notification from "../AccountSettings/Notifications";
import Payment from "../AccountSettings/Payment";
import TableCell from "@mui/material/TableCell";

const Settings = () => {
  const [value, setValue] = React.useState("1");
  return (
    <div>
      <SettingHeader />
      <div style={{ marginLeft: "1em" }}>
        <Box display={"flex"} gap={"8px"}>
          <Button
            onClick={() => setValue("1")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "1" ? "#F4FFF4" : "#F4F4F4",
              color: value === "1" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Profile
          </Button>
          <Button
            onClick={() => setValue("2")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "2" ? "#F4FFF4" : "#F4F4F4",
              color: value === "2" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Security
          </Button>
          <Button
            onClick={() => setValue("3")}
            sx={{
              fontSize: { xs: "11px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "3" ? "#F4FFF4" : "#F4F4F4",
              color: value === "3" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Notifications
          </Button>
          <Button
            onClick={() => setValue("4")}
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              textTransform: "capitalize",
              bgcolor: value === "4" ? "#F4FFF4" : "#F4F4F4",
              color: value === "4" ? "#253528" : "#969696",
              borderRadius: "6px",
              padding: "5px 30px",
              "&:hover": {
                bgcolor: "#F4FFF4",
                color: "#253528",
              },
            }}>
            Payments
          </Button>
        </Box>
        {value === "1" && <Profile />}
        {value === "2" && <Security />}
        {value === "3" && <Notification />}
        {value === "4" && <Payment />}
      </div>
      <div>
        <TableCell align="left"></TableCell>
      </div>
      {/* <h1>Rght??</h1> */}
    </div>
  );
};

export default Settings;
