import React from "react";
import { useMutation } from "react-query";
import { createShopGroup, updateShopGroup } from "api/userApi";

import { useFormik } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";

import styled from "styled-components";
import Input from "components/GenericComponents/Input/index";
import Button from "components/GenericComponents/Button/index";
import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";

import UserContext from "context/users";

const ButtonContainer = styled.div`
text-align: center;
 }
`;
const validationSchema = Yup.object({
  groupName: Yup.string().required("Group Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  groupId,
  disabled = false,
  handleDisabled,
  type = "add",
  btnText,
}) {
  const initialValues = {
    groupName: data?.groupName || "",
    description: data?.description || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateShopGroupMutate(
          { ...values, groupId: data._id },
          {
            onSuccess: (data) => {
              setEditableProperties(data.group);
              onSubmit();
            },
          }
        );
      } else {
        createShopGroupMutate(
          { ...values, groupId },
          {
            onSuccess: (data) => {
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });

  const { mutate: createShopGroupMutate } = useMutation(createShopGroup);
  const { mutate: updateShopGroupMutate } = useMutation(updateShopGroup);

  const handleRadioChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };

  let { setEditableProperties } = React.useContext(UserContext);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "15px" }}>
          <Grid item xs={12}>
            <Input
              label="Group Name :"
              required
              name="groupName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values?.groupName}
              error={
                formik.touched.groupName && formik.errors.groupName
                  ? formik.errors.groupName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineTextFields
              onChange={formik.handleChange}
              label={"Description"}
              name="description"
              value={formik.values.description}
              disabled={disabled}
              size="1%"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Group"}
                type="submit"
                display={disabled ? "none" : ""}
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
