import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { Add, Close } from "@mui/icons-material";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./style.css";

import TableCell from "@mui/material/TableCell";
const GarageHeader = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 340, sm: 520, md: 800 },
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 1,
  };
  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} position={"relative"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}>
              <img src="/logo.svg" style={{ width: "214px" }} alt="logo" />
            </Typography>
            <Box display={"flex"} width={"100%"}>
              <Box sx={{ flex: 1, display: { xs: "none", md: "block" } }}>
                <img
                  src="/modalimg.png"
                  style={{ objectFit: "cover" }}
                  alt="modalimg"
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4  px",
                }}>
                <Typography
                  fontSize={65}
                  lineHeight={1}
                  textAlign={"center"}
                  color={"#253528"}
                  fontWeight={"bold"}>
                  Download
                </Typography>
                <Typography
                  fontSize={50}
                  lineHeight={1}
                  textAlign={"center"}
                  color={"#253528"}
                  fontWeight={"bold"}>
                  Our App
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}>
                  <div>
                    <Link>
                      <img
                        class="bn45"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                        alt="bn45"
                      />
                    </Link>
                  </div>
                  <div>
                    <Link>
                      <img
                        class="bn46"
                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-Shop.svg"
                        alt="bn45"
                      />
                    </Link>
                  </div>
                </Box>

                <img src="/qr.png" alt="qr" />
              </Box>
            </Box>
            <Box
              position={"absolute"}
              top={10}
              left={10}
              bgcolor={"#8EAB90"}
              display={"flex"}
              alignItems={"center"}
              borderRadius={"20px"}
              p={1}
              onClick={handleClose}
              sx={{ cursor: "pointer", color: "#707070" }}>
              <Close />
            </Box>
          </Box>
        </Modal>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            backgroundColor: "#fff",
            p: 1,
            marginBottom: "1em",
            borderTop: "1px solid #d6d6d6",
            borderBottom: "1px solid #d6d6d6",
            padding: "1rem",
          }}
          className="right-navbar">
          <Typography
            fontSize={20}
            fontWeight={"bold"}
            color="#253528"
            sx={{ marginTop: "4px" }}>
            My Garage
          </Typography>
          {/* <Button
            onClick={handleOpen}
            variant="outlined"
            startIcon={<Add sx={{ fontSize: "22px", color: "white" }} />}
            className="add-vehicle-btn"
            size="small"
            style={{
              backgroundColor: "#87AA8D",
              color: "white",
              borderRadius: "8px",
              fontWeight: "500",
              fontSize: "15px",
              borderColor: "#87AA8D",
              padding: "5px 15px",
              border: "1px solid white",
            }}>
            Add Vehicle
          </Button> */}

          <button
            className="add-vehicle-btn1 add-vehicle-btn"
            onClick={handleOpen}>
            + <span>Add Vehicle</span>
          </button>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
};

export default GarageHeader;
