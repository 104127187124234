import React from "react";
import styled from "styled-components";
const Button = styled.button`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#42B94C"};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#42B94C"};
  display: ${(props) => (props.display ? props.display : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  padding: 9px 15px;
  min-width: 80 px;
  color: ${(props) => (props.color ? props.color : "white")};
  &:hover {
    ${(props) => (props.hoverEffect ? props.hoverEffect : "color: white;")}
  }
  border: 1px solid #42b94c;
`;
export default function Index({
  color,
  backgroundColor,
  children,
  hoverEffect,
  ...props
}) {
  return (
    <>
      <Button
        backgroundColor={backgroundColor}
        color={color}
        hoverEffect={hoverEffect}
        {...props}
      >
        {children}
      </Button>
    </>
  );
}
