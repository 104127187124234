import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import RowRadioButtonsGroup from "components/GenericComponents/RadioGroup/index";
import RatingTypeRadioGroup from "components/GenericComponents/RatingTypeRadioGroup/index";
import PhotoWeightingRadioGroup from "components/GenericComponents/PhotoWeightingRadioGroup/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { useMutation } from "react-query";
import { createPromptCategory, updatePromptCategory } from "api/api";

import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";
import PromptContext from "context/prompt";
import { FormatBold, StrikethroughS, WidthNormalSharp } from "@mui/icons-material";

const ButtonContainer = styled.div`
text-align: center;
 }
`;

const validationSchema = Yup.object({
  categoryName: Yup.string().required("Category Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  sectionId,
  disabled = false,
  handleDisabled,
  type = "add",
  btnText,
}) {
  let { setEditableProperties } = React.useContext(PromptContext);
  const initialValues = {
    categoryName: data?.categoryName || "",
    categoryOptional: data?.categoryOptional || false,
    description: data?.description || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateCategoryMutate(
          { ...values, categoryId: data._id },
          {
            onSuccess: (data) => {
              onSubmit();
              setEditableProperties(data?.category);
            },
          }
        );
      } else {
        createCategoryMutate(
          { ...values, sectionId },
          {
            onSuccess: () => {
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createCategoryMutate } = useMutation(createPromptCategory);
  const { mutate: updateCategoryMutate } = useMutation(updatePromptCategory);

  const handleRadioChange = (key, value) => {
    formik.setFieldValue(key, value);
  };
  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "15px" }}>
          <Grid item xs={12}>
            <Input
              label="Category Name"
              required
              name="categoryName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.categoryName}
              error={
                formik.touched.categoryName && formik.errors.categoryName
                  ? formik.errors.categoryName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Item is OPTIONAL"
              name="categoryOptional"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.categoryOptional}
              value={formik.values.categoryOptional}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineTextFields
              onChange={formik.handleChange}
              label={"Description"}
              name="description"
              value={formik.values.description}
              disabled={disabled}
              size="1%"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Category"}
                type="submit"
                display={disabled ? "none" : ""}
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
