import React, { useEffect } from "react";
import PublicLayout from "../components/PublicLayout";
import AppRoutes from "../routes/appRoutes";
import styled from "styled-components";

const RootContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(255, 255, 255);
`;

export default function MainUnAuthenticated() {
  return (
    <>
      <PublicLayout>
        <RootContainer>
          <AppRoutes />
        </RootContainer>
      </PublicLayout>
    </>
  );
}
