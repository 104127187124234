import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Outlet } from "react-router-dom";
import useAuth from "../../Hooks/useAuthHook";
import { Avatar } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {
  AccountCircle,
  CalendarToday,
  CalendarTodayOutlined,
  DirectionsCarFilled,
  Inbox,
  InboxOutlined,
  LocalOffer,
  LocalOfferOutlined,
  MiscellaneousServices,
  MiscellaneousServicesOutlined,
  Search,
  Settings,
} from "@mui/icons-material";
import "./style.css";
// import Navbar from "../PublicLayout/navbar";
import NavBarHeader from "../PublicLayout/navbarHeader";

const drawerWidth = 260;

// eslint-disable-next-line react/prop-types
// const pages = ["buy", "sell", "news", "about"];
function Index({ window }) {
  // const navigator = useNavigate();
  const { user, logout: authlogout } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const myRef = useRef(null);
  const [contHeight, setContHeight] = useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleMenu = (event) => {
    console.log(event, "event");
    // setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handlePage = (page) => {
  //   switch (page) {
  //     case "buy":
  //       return "dealerships";
  //     case "sell":
  //       return "how-to-list-with-checkdr";
  //     default:
  //       return page;
  //   }
  // };
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const AdminMenuItems = [
    {
      name: "Inspection Definitions",
      path: "/inspectionDefinitions",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
    {
      name: "Shops",
      path: "/shops",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
    {
      name: "MakeImages",
      path: "/makeImages",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
  ]

  const MenuItems = [
    {
      name: "Manage Listings",
      path: "/adminAvailableListings",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
    {
      name: "Add/Edit Listing",
      path: "/adminSingleListing",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
    {
      name: "Live Listings View",
      path: "/availableListing",
      icon: <SearchOutlinedIcon />,
      activeIcon: <Search sx={{ color: "white" }} />,
    },
    /*{
      name: "Inventory",
      path: "/inventory",
      icon: <DirectionsCarOutlinedIcon />,
      activeIcon: <DirectionsCarFilled sx={{ color: "white" }} />,
    },*/
    {
      name: "Users",
      path: "/testDrives",
      icon: <CalendarTodayOutlined />,
      activeIcon: <CalendarToday sx={{ color: "white" }} />,
    },
    {
      name: "Offers/Activity",
      path: "/offers",
      icon: <LocalOfferOutlined />,
      activeIcon: <LocalOffer sx={{ color: "white" }} />,
    },
    /*{
      name: "Chat",
      path: "/chat",
      icon: <InboxOutlined />,
      activeIcon: <Inbox sx={{ color: "white" }} />,
    },*/
    {
      name: "ChatGPT",
      path: "/chatgpt",
      icon: <SettingsOutlinedIcon />,
      activeIcon: <Settings sx={{ color: "white" }} />,
    },
    {
      name: "Settings",
      path: "/settings",
      icon: <SettingsOutlinedIcon />,
      activeIcon: <Settings sx={{ color: "white" }} />,
    },
    /*{
      name: "Locations",
      path: "/services",
      icon: <MiscellaneousServicesOutlined />,
      activeIcon: <MiscellaneousServices sx={{ color: "white" }} />,
    },*/
    /*{
      name: "Values",
      path: "/services",
      icon: <MiscellaneousServicesOutlined />,
      activeIcon: <MiscellaneousServices sx={{ color: "white" }} />,
    },*/
  ];

  if (sessionStorage.getItem("car_id") && user) {
    //call the api

    console.log(
      `Save the listinging with id ${sessionStorage.getItem(
        "car_id"
      )} for user ${user._id}`
    );
  }

  const handleLogOut = () => {
    if (sessionStorage.getItem("car_id")) {
      sessionStorage.removeItem("car_id");
    }
    authlogout();
  };
  // const isLargeScreen = useMediaQuery("(max-width: 525px)");
  // useEffect(() => {
  //   console.log(myRef.current.clientHeight, "ref=================");
  //   setContHeight(myRef.current.clientHeight);
  // }, []);
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          setContHeight(myRef.current.clientHeight);
        }
      }
    });

    if (myRef.current) {
      observer.observe(myRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  const drawer = (
    <div className="drawer" style={{ position: "relative" }}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "1em",
        }}
      >
        <Typography
          component="a"
          href="https://checkdrive.co/"
          display={"flex"}
        >
          {!isLargeScreen ? (
            <img
              src="/logo.svg"
              style={{ width: "180px", marginInline: "auto" }}
              alt="logo"
            />
          ) : (
            <img
              src="/Check.png"
              style={{ width: "40px", height: "40px", marginInline: "auto" }}
              alt="logo"
            />
          )}
        </Typography>
      </div> */}
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="https://checkdrive.co/"
        sx={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
        }}
        className="navbar-logo">
        <img src="/logo.svg" alt="logo" style={{ width: "180px" }} />
      </Typography>
      <List id="style-10" style={{ overflow: "auto" }}>
        <ListItem sx={{ display: "flex", margin: "10px 0" }}>
          <Link to="/settings">
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="gray"
              sx={{ padding: 0.6 }}>
              {user.profilePic ? (
                <Avatar size="small" src={user.profilePic} alt={user.name} />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          </Link>

          <Box sx={{ color: "#969696" }}>
            <Typography fontSize={13}>{user.name}</Typography>
            <Typography fontSize={12}>{user.email}</Typography>
          </Box>
        </ListItem>
        
        <div style={{ margin: "15px 0" }}>
          <Typography>Admin Links</Typography>

          {AdminMenuItems.map((text, index) => (
            <Link
              to={text.path}
              key={index}
              className={
                text.name === "Test Drives" || text.name === "Chat"
                  ? "disabled-link"
                  : ""
              }
              style={{ textDecoration: "none", color: "#969696" }}>
              <ListItem sx={{ p: 0.6 }}>
                {text.path === location.pathname ? (
                  <ListItemButton
                    sx={{
                      borderRadius: "13px",
                      bgcolor: "#87AC8D",
                      margin: "0 20px",
                      "&:hover": { backgroundColor: "#87AC8D" },
                    }}>
                    <ListItemIcon>{text.activeIcon}</ListItemIcon>
                    <Typography fontSize={"15px"} color={"white"}>
                      {text.name}
                    </Typography>
                  </ListItemButton>
                ) : (
                  <>
                    <ListItemButton
                      disableRipple
                      disabled={
                        text.name === "Test Drives" || text.name === "Chat"
                      }
                      sx={{ borderRadius: "13px", margin: "0 20px" }}>
                      <ListItemIcon>{text.icon}</ListItemIcon>
                      <Typography fontSize={"15px"}>{text.name}</Typography>
                    </ListItemButton>

                    {(text.name === "Test Drives" || text.name === "Chat") && (
                      <button className="coming-soon-btn">Coming soon</button>
                    )}
                  </>
                )}
              </ListItem>
            </Link>
          ))}
        </div>

        <Divider style={{ width: "80%", display: "flex", margin: "auto" }} />

        <div style={{ margin: "15px 0" }}>
          {MenuItems.map((text, index) => (
            <Link
              to={text.path}
              key={index}
              className={
                text.name === "Test Drives" || text.name === "Chat"
                  ? "disabled-link"
                  : ""
              }
              style={{ textDecoration: "none", color: "#969696" }}>
              <ListItem sx={{ p: 0.6 }}>
                {text.path === location.pathname ? (
                  <ListItemButton
                    sx={{
                      borderRadius: "13px",
                      bgcolor: "#87AC8D",
                      margin: "0 20px",
                      "&:hover": { backgroundColor: "#87AC8D" },
                    }}>
                    <ListItemIcon>{text.activeIcon}</ListItemIcon>
                    <Typography fontSize={"15px"} color={"white"}>
                      {text.name}
                    </Typography>
                  </ListItemButton>
                ) : (
                  <>
                    <ListItemButton
                      disableRipple
                      disabled={
                        text.name === "Test Drives" || text.name === "Chat"
                      }
                      sx={{ borderRadius: "13px", margin: "0 20px" }}>
                      <ListItemIcon>{text.icon}</ListItemIcon>
                      <Typography fontSize={"15px"}>{text.name}</Typography>
                    </ListItemButton>

                    {(text.name === "Test Drives" || text.name === "Chat") && (
                      <button className="coming-soon-btn">Coming soon</button>
                    )}
                  </>
                )}
              </ListItem>
            </Link>
          ))}
        </div>

        <Divider style={{ width: "80%", display: "flex", margin: "auto" }} />

        <div style={{ margin: "10px 0" }}>
          <ListItem sx={{ p: 0.6, color: "#969696" }}>
            <ListItemButton
              sx={{
                margin: "0 20px",
              }}>
              <ListItemIcon>
                <HelpOutlineOutlinedIcon />
              </ListItemIcon>
              <Typography fontSize={"15px"}>Help</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 0.6, color: "#969696" }}>
            <ListItemButton
              onClick={handleLogOut}
              sx={{
                margin: "0 20px",
              }}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <Typography fontSize={"15px"}>Logout</Typography>
            </ListItemButton>
          </ListItem>
        </div>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const clickonMenu = () => {
    var navbar = document.getElementById("navbar");
    navbar.classList.toggle("show");
  };

  const closeNavbar = () => {
    var navbar = document.getElementById("navbar");
    navbar.classList.remove("show");
    navbar?.classList?.toggle("none");
  };

  return (
    <>
      {/*
      <div className="top_div" ref={myRef}>
        <NavBarHeader />
      </div>
      */}
      <div style={{ position: "relative" }}>
        <div onClick={clickonMenu} className="responsive-navbar">
          <img
            src="./menu.png"
            alt="menu"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
        <div className="navbar" id="navbar">
          <div className="close" onClick={closeNavbar}>
            <img src="./close.svg" alt="close" />
          </div>
          <Box
            component="nav"
            sx={{
              width: { sm: drawerWidth },
              height: { sm: `calc(100% - ${contHeight}px)` },
              flexShrink: { sm: 0 },
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            aria-label="mailbox folders"
            className="drawer_parent_mobile ">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}>
              {drawer}
            </Drawer>
            <Drawer variant="permanent" open>
              {drawer}
            </Drawer>
          </Box>
        </div>

        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            component="nav"
            sx={{
              width: { sm: drawerWidth },
              height: { sm: `calc(100% - ${contHeight}px)` },
              flexShrink: { sm: 0 },
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            aria-label="mailbox folders"
            className="drawer_parent">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
              }}>
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
              }}
              open>
              {drawer}
            </Drawer>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              overflowX: "hidden",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              marginRight: "5px",
              "@media (min-width: 600px)": {
                marginLeft: "260px",
              },
            }}>
            <Outlet />
          </Box>
        </Box>
      </div>
      <div style={{ display: "none" }}>
        <TableCell align="left"></TableCell>
      </div>
    </>
  );
}

Index.propTypes = {
  window: PropTypes.func,
};

export default Index;
