import React from "react";
import Grid from "@mui/material/Grid";
import PageTitle from "../../components/GenericComponents/PageTitle/index";
import styled from "styled-components";
import Button from "../../components/GenericComponents/Button/index";
import { useNavigate, useLocation } from "react-router";
import { useQuery } from "react-query";
import { getInspectionDefinitions } from "api/api";
import Modal from "components/GenericComponents/Modal/index";
import Form from "../../components/InspectionDefinition/InspectionForm/index";
import EditInspection from "../../components/InspectionDefinition/EditInspectionProperties/index.js";
import InspectionContext from "context/inspection";

const Properties = React.lazy(() =>
  import("../../components/InspectionDefinition/InspectionProperties/index")
);
const List = React.lazy(() => import("../../components/InspectionDefinition/InspectionList/index"));
const ButtonContainer = styled.div`
margin-left: 6px;
margin-bottom: 8px;
margin-top: -38px;
float: right;
}
`;
const Title = styled.div`
  text-align: center;
  font-size: larger;
`;
export default function Index() {
  // const navigate = useNavigate();
  // let { pathname } = useLocation();
  
  const [modal, setModal] = React.useState(false);
  const [selectedInspection, setSelectedInspection] = React.useState(null);
  let { currentSelectedInspection } = React.useContext(InspectionContext);
  const inspectionData = useQuery("getInspectionDefinitions", getInspectionDefinitions, {});

  const handleSelectInspection = (inspection) => {
    setSelectedInspection(inspection);
  };
  return (
    <>
      <React.Suspense>
        <PageTitle title="Inspection Management" />
        <Modal
          open={modal}
          onclose={() => {
            setModal(false);
          }}
          title={
            <>
              <span> Add Inspection</span>
            </>
          }
        >
          <Form
            onSubmit={() => {
              inspectionData.refetch();
              setModal(false);
            }}
          />
        </Modal>
        <ButtonContainer>
          <Button
            children={"Add Inspection"}
            borderColor="#42B94C"
            onClick={() => setModal(true)}
          />
        </ButtonContainer>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Title>Inspection List</Title>
            <List
              listData={inspectionData?.data?.inspectionDefintions}
              handleSelectInspection={handleSelectInspection}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Properties</Title>
            <Properties
              selectedInspection={
                currentSelectedInspection
                  ? currentSelectedInspection
                  : inspectionData.data?.inspectionDefintions?.[0]?.items?.[0]
              }
              inspectionDataRefetch={inspectionData.refetch}
            />
          </Grid>
          <Grid item xs={5}>
            <Title>Edit Properties</Title>
            <EditInspection
              name="inspection"
              inspectionDataRefetch={inspectionData.refetch}
            />
          </Grid>
        </Grid>
      </React.Suspense>
    </>
  );
}
