import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import CheckboxesGroup from "components/GenericComponents/checkbox/groupCheckbox";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { createPrompt, updatePrompt } from "api/api";
import { useMutation, useQuery } from "react-query";
import PromptContext from "context/prompt";
import Modal from "components/GenericComponents/Modal/index";
import { Label } from "@mui/icons-material";
const ButtonContainer = styled.div`
text-align: center;
 }
`;
const AddNewButtonContainer = styled.div`
  float: right;
  margin-top: -16px;
  margin-right: 23px;
`;
const InputContainer = styled.div`
  margin-left: 7%;
`;
const validationSchema = Yup.object({
  promptName: Yup.string().required("Prompt Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  disabled = false,
  btnText,
  handleDisabled,
  type = "add",
}) {
  let {
    setPropertyName,
    setEditableProperties,
    setCurrentSelectedPrompt,
    changeIndex,
  } = React.useContext(PromptContext);
  const [modal, setModal] = React.useState(false);

  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };
  const initialValues = {
    promptName: data?.PromptName || "",
    description: data?.description || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (data) {
        updatePromptMutate(
          { ...values, promptId: data?._id },
          {
            onSuccess: (data) => {
              setEditableProperties(data?.UpdatedPrompts);
              setCurrentSelectedPrompt(data?.UpdatedPrompts);
              onSubmit();
            },
          }
        );
      } else {
        createIncpectionMutate(
          { ...values },
          {
            onSuccess: (data) => {
              setPropertyName("Prompt");
              changeIndex();
              setEditableProperties(data.createdPrompts);
              setCurrentSelectedPrompt(data.createdPrompts);

              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createIncpectionMutate } = useMutation(createPrompt);
  const { mutate: updatePromptMutate } = useMutation(updatePrompt);
  const handleCheckBoxChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <Input
              label="Immutable ID: (For use from ios app)"
              required
              name="Immutable ID"
              value={data?._id}
              disabled="true"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Prompt Name: "
              required
              name="PromptName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.PromptName}
              error={
                formik.touched.PromptName && formik.errors.PromptName
                  ? formik.errors.PromptName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Prompt"}
                type="submit"
                display={disabled ? "none" : ""}
                borderColor="#42B94C"
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
