import React from 'react'

const CarCard = () => {
  return (
    <div>CarCard</div>
  )
}

function extraUrlChars(inspection) {
    let make = inspection?.vehicleInfo?.vehicleType?.vehicleMake;
    let model = inspection?.vehicleInfo?.vehicleType?.vehicleModel;
    let year = inspection?.vehicleInfo?.vehicleType?.year;
    let vinLastTen = inspection?.vehicleInfo?.vin.slice(-10);
    var extraChars = "used-" + make + "-" + model + "-" + year + "-" + vinLastTen;
    return extraChars;
  }
  
  export function get_image_urls(inspection = '', mediaType = 'photoUrl', photoWeightedOnly = true ) {
    var ourPhotoURLs = [];
  
    inspection?.inspectionType?.sections.forEach((section)=> {
      if(section?.$mediaType && section?.sectionName){
        ourPhotoURLs.push([section?.photoUrl, section?.sectionName]);
      }
    
      section?.categories.forEach((category)=>{
        if(category?.photoUrl && category?.categoryName && category?.photoWeightEnabled === "Yes"){
          ourPhotoURLs.push([category?.photoUrl, category?.categoryName]);
        }
      }); //end category
  
    }); //end section
  
    return ourPhotoURLs;
  };

export default CarCard
