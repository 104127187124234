import { Box, Typography } from "@mui/material";
import React from "react";

const SettingHeader = () => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          backgroundColor: "#fff",
          p: 1,
          marginBottom: "1em",
          borderTop: "1px solid #d6d6d6",
          borderBottom: "1px solid #d6d6d6",
          padding: "1rem",
        }}
        className="right-navbar"
      >
        <Typography fontSize={20} fontWeight={"bold"} color="#253528">
          Settings
        </Typography>
      </Box>
    </Box>
  );
};

export default SettingHeader;
