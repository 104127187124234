import axios from "axios";
import { instance, publicInstance, publicFileDownload } from "./axiosConfig";


/*
InspectionJob Functions
*/
//create inspection job
/*
{
      inspectionType,
      bookedBy,
      mechanicId,
      vehicleInfo,
      customerInfo,
      jobStatus,
      userId,
      inspectionClassification,
      reviewStatus,
      vehiclePhysicalLocation
    }*/
export async function createInspectionJob(_data) {

  console.log("creatingInspectionJob: " + JSON.stringify(_data));

  let { data } = await instance.post("/inspectionJob/createInspectionJob", _data);

  return data;
}

export async function updateInspectionJob(_data) {

  console.log("updateInspection job: " + JSON.stringify(_data));

  let { data } = await instance.put("/inspectionJob/updateInspectionJob", _data);

  return data;
}