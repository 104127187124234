import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { useMutation } from "react-query";
import { createNewType } from "api/api";

const ButtonContainer = styled.div`
text-align: center;
 }
`;
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  disabled = false,
  btnText,
}) {
  const initialValues = {
    name: data?.name || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (data) {
        // updateItemMutate(
        //   { ...values, itemId: data._id },
        //   {
        //     onSuccess: () => {
        //       onSubmit();
        //     },
        //   }
        // );
      } else {
        createNewTypeMutate(
          { ...values },
          {
            onSuccess: () => {
              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createNewTypeMutate } = useMutation(createNewType);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "15px" }}>
          <Grid item xs={12}>
            <Input
              label="Applies To Name"
              required
              name="name"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.appliesToName}
              error={
                formik.touched.appliesToName && formik.errors.appliesToName
                  ? formik.errors.appliesToName
                  : null
              }
              // disabled={disabled}
            />
          </Grid>

          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add New Type"}
                type="submit"
                // display={disabled ? "none" : ""}
              />
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
