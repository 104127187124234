import React from "react";
import UserContext from "context/users";
import { getShops, getGroups } from "api/userApi";

export default function UsersProvider({ children }) {
  //shops
  const [shopData, setShopData] = React.useState(null);
  const [selectedShopIndex, setSelectedShopIndex] = React.useState(0);
  const [currentSelectedShop, setCurrentSelectedShop] = React.useState(null);
  const [shopPropertyName, setShopPropertyName] = React.useState(null);
  const [shopEditableProperties, setShopEditableProperties] = React.useState(null);

  //groups
  const [groupData, setGroupData] = React.useState(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = React.useState(0);
  const [currentSelectedGroup, setCurrentSelectedGroup] = React.useState(null);
  const [groupPropertyName, setGroupPropertyName] = React.useState(null);
  const [groupEditableProperties, setGroupEditableProperties] = React.useState(null);

  //not sure 
  const [unUsedIndex, setUnsedIndex] = React.useState(null);
  const [unUsedGroupIndex, setUnsedGroupIndex] = React.useState(null);

  //not sure 
  const changeShopIndex = async () => {
    const shopsData = await getShops();
    setSelectedShopIndex(shopsData?.data?.[0]?.groups.length - 1);
  };

  //not sure 
  const setFirstEditableShopProperty = async () => {
    const shopsData = await getShops();

    setShopEditableProperties(
      //shopsData?.[shopsData?.length > 1 ? 1 : 0]?.items[0]
    );

    setShopPropertyName(
      shopsData?.data?.length > 0 ? "shop" : ""
    );
  };

  const setFirstEditableGroupProperty = async () => {
    const groupsData = await getGroups(currentSelectedShop?._id);

    setGroupEditableProperties(
      //groupsData?.[groupsData?.length > 1 ? 1 : 0]?.items[0]
    );

    setGroupPropertyName(
      groupsData?.data?.length > 0 ? "group" : ""
    );
  };

  //not sure what we need
  return (
    <>
      <UserContext.Provider
        value={{
          //shops
          shopData, setShopData,
          selectedShopIndex, setSelectedShopIndex,
          currentSelectedShop, setCurrentSelectedShop,
          shopPropertyName, setShopPropertyName,
          shopEditableProperties, setShopEditableProperties,
          setFirstEditableShopProperty,
          
          //groups
          groupData, setGroupData,
          selectedGroupIndex, setSelectedGroupIndex,
          currentSelectedGroup, setCurrentSelectedGroup,
          groupPropertyName, setGroupPropertyName,
          groupEditableProperties, setGroupEditableProperties,
          setFirstEditableGroupProperty,

          changeShopIndex,
          unUsedIndex,
          setUnsedIndex,
          unUsedGroupIndex,
          setUnsedGroupIndex,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
}
