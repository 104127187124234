import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/GenericComponents/Input/index";
import CheckboxesGroup from "components/GenericComponents/checkbox/groupCheckbox";
import MultilineTextFields from "components/GenericComponents/MultiLineTextField/index";
import Button from "components/GenericComponents/Button/index";
import styled from "styled-components";
import { createInspection, updateInspection, getNewTypes } from "api/api";
import { useMutation, useQuery } from "react-query";
import InspectionContext from "context/inspection";
import Modal from "components/GenericComponents/Modal/index";
import AddNewAppliesTo from "../InspectionForm/addNewAppliesTo.js";
import { Label } from "@mui/icons-material";
const ButtonContainer = styled.div`
text-align: center;
 }
`;
const AddNewButtonContainer = styled.div`
  float: right;
  margin-top: -16px;
  margin-right: 23px;
`;
const InputContainer = styled.div`
  margin-left: 7%;
`;
const validationSchema = Yup.object({
  inspectionName: Yup.string().required("Inspection Name is required"),
});
export default function Index({
  data = null,
  onSubmit,
  disabled = false,
  btnText,
  handleDisabled,
  type = "add",
}) {
  
  let {
    setPropertyName,
    setEditableProperties,
    setCurrentSelectedInspection,
    changeIndex,
  } = React.useContext(InspectionContext);

  const [modal, setModal] = React.useState(false);
  const optionData = useQuery("newTypes", getNewTypes);

  const handleButtonDisabled = (value) => {
    handleDisabled(value);
  };
  const initialValues = {
    inspectionName: data?.inspectionName || "",
    mechanicPayment: data?.mechanicPayment || "",
    noRatings: data?.noRatings || false,
    isDIYJob: data?.isDIYJob || false,
    jobRequiresReview: data?.jobRequiresReview || false,
    photoModeHorizontal: data?.photoModeHorizontal || false,
    jobCreatesListings: data?.jobCreatesListings || false,
    description: data?.description || "",
    appliesTo: data?.appliesTo || optionData?.data?.appliesTo,
    customerIncludeExcludeList: data?.customerIncludeExcludeList || [
      { name: "BMW", value: false },
      { name: "Chevy", value: false },
      { name: "Tesla", value: false },
      { name: "Uber", value: false },
      { name: "HopSkipDrv", value: false },
    ],
  };
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (data) {
        updateInspectionMutate(
          { ...values, inspectionId: data?._id },
          {
            onSuccess: (data) => {
              setEditableProperties(data?.UpdatedInspections);
              setCurrentSelectedInspection(data?.UpdatedInspections);
              onSubmit();
            },
          }
        );
      } else {
        createIncpectionMutate(
          { ...values },
          {
            onSuccess: (data) => {
              setPropertyName("inspection");
              changeIndex();
              setEditableProperties(data.createdInspections);
              setCurrentSelectedInspection(data.createdInspections);

              onSubmit();
              resetForm();
            },
          }
        );
      }
    },
  });
  const { mutate: createIncpectionMutate } = useMutation(createInspection);
  const { mutate: updateInspectionMutate } = useMutation(updateInspection);

  const handleCheckBoxChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  return (
    <>
      <Modal
        open={modal}
        onclose={() => {
          setModal(false);
        }}
        title={
          <>
            <span> Add New Type</span>
          </>
        }
      >
        <AddNewAppliesTo
          onSubmit={() => {
            setModal(false);
            optionData?.refetch();
          }}
        />
      </Modal>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <Input
              label="Inspection Name: "
              required
              name="inspectionName"
              onChange={formik.handleChange}
              //  onBlur={formik.handleBlur}
              value={formik.values.inspectionName}
              error={
                formik.touched.inspectionName && formik.errors.inspectionName
                  ? formik.errors.inspectionName
                  : null
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Mechanic Payment: "
              name="mechanicPayment"
              onChange={formik.handleChange}
              value={formik.values.mechanicPayment}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="DIY Inspection"
              name="isDIYJob"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.isDIYJob}
              value={formik.values.isDIYJob}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="No ratings for steps"
              name="noRatings"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.noRatings}
              value={formik.values.noRatings}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Review Required After Inspection"
              name="jobRequiresReview"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.jobRequiresReview}
              value={formik.values.jobRequiresReview}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="This inspection can create listings"
              name="jobCreatesListings"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.jobCreatesListings}
              value={formik.values.jobCreatesListings}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Change Photo Orientation to Horizontal"
              name="photoModeHorizontal"
              type="checkbox" 
              onChange={formik.handleChange}
              checked={formik.values.photoModeHorizontal}
              value={formik.values.photoModeHorizontal}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxesGroup
              title={"Applies to:"}
              options={
                formik.values?.appliesTo
                  ? formik.values?.appliesTo
                  : optionData?.data?.appliesTo
              }
              name="appliesTo"
              handleCheckBoxChange={handleCheckBoxChange}
              value={formik.values?.appliesTo}
              disabled={disabled}
            />
            {!disabled && type === "add" ? (
              <AddNewButtonContainer>
                <Button
                  children={"Add New Type"}
                  onClick={() => setModal(true)}
                  type="button"
                />
              </AddNewButtonContainer>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <CheckboxesGroup
              title={"Customer Include/exclude list:"}
              options={
                formik.values?.customerIncludeExcludeList
                  ? formik.values?.customerIncludeExcludeList
                  : [
                      { name: "BMW", value: false },
                      { name: "HopSkipDrv", value: false },
                    ]
              }
              name="customerIncludeExcludeList"
              value={formik.values?.customerIncludeExcludeList}
              handleCheckBoxChange={handleCheckBoxChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineTextFields
              onChange={formik.handleChange}
              label={"Description"}
              name="description"
              value={formik.values.description}
              disabled={disabled}
              size="3%"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonContainer>
              {" "}
              <Button
                children={btnText ? btnText : "Add Inspection"}
                type="submit"
                display={disabled ? "none" : ""}
                borderColor="#42B94C"
              />
              {!disabled && type === "edit" ? (
                <Button
                  children={"Cancel"}
                  type="button"
                  display={disabled ? "none" : ""}
                  marginLeft="12px"
                  backgroundColor="white"
                  color="#42B94C"
                  hoverEffect="background:#addeb1;:white;"
                  borderColor="#42B94C"
                  onClick={() => handleButtonDisabled(true)}
                />
              ) : (
                ""
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
