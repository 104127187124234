import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import AuthRoutes from "./authRoutes";

//screens
import Main from "../screens/main";
import Account from "../screens/AccountSettings/Account";
import Payment from "../screens/AccountSettings/Payment";
import Profile from "../screens/AccountSettings/Profile";
import Security from "../screens/AccountSettings/Security";
import Notification from "../screens/AccountSettings/Notifications";

import Inspection from "../screens/Inspections";
import Shops from "../screens/Shops";
import AdminSingleListing from "../screens/AdminSingleListing";
import MakeImages from "../screens/MakeImages";
import MyGarage from "../screens/MyGarage/CarGarage";
import Offers from "../screens/Offers/Offers";
import Chat from "../screens/Chat/Chat";
import ChatGPT from "../screens/ChatGptPrompts";
import TestDrives from "../screens/TestDrives/TestDrives";
import Services from "../screens/Services/Services";
import ListingDetailsWrapper from "../screens/Listings/ListingDetailsWrapper";

import SpecificOffer from "../screens/Offers/components/SpecificOffer";

//public
import MainUnAuthenticated from "../screens/mainUnAuthenticated";
import AvailableListingIndex from "../screens/Listings/AvailableListingIndex";
import AdminAvailableListingIndex from "../screens/Listings/AdminAvailableListingIndex";
import AddAdds from "../screens/PrivacyPolicy/app-ads";

//functions
import useAuth from "../Hooks/useAuthHook";
import Settings from "../screens/Settings/Settings";

const Login = React.lazy(() => import("../screens/Auth/login"));
const ShowReport = React.lazy(() => import("../screens/reports/index"));
const PrivacyPolicy = React.lazy(() =>
  import("../screens/PrivacyPolicy/index")
);

export default function Index() {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <React.Suspense>
        {isAuthenticated ? (
          <>
            <Routes>
              <Route path="" element={<Main />}>
                <Route path="/inspectionDefinitions" element={<Inspection />} />
                <Route path="/shops" element={<Shops />} />
                <Route path="/adminSingleListing" element={<AdminSingleListing />} />
                <Route path="/adminSingleListing/:id" element={<AdminSingleListing />} />
                <Route path="/makeImages" element={<MakeImages />} />
                <Route path="/myGarage" element={<MyGarage />} />
                <Route path="/offers" element={<Offers />} />

                <Route path="/offers/:id" element={<SpecificOffer />} />
                <Route path="/services" element={<Services />} />
                <Route path="/chatgpt" element={<ChatGPT />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/testDrives" element={<TestDrives />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/account/settings" element={<Account />} />
                <Route path="/account/payments" element={<Payment />} />
                <Route path="/account/profile" element={<Profile />} />
                <Route path="/account/security" element={<Security />} />
                <Route
                  path="/account/notifications"
                  element={<Notification />}
                />
                <Route
                  path="/adminAvailableListings"
                  element={<AdminAvailableListingIndex />}
                />
                <Route
                  path="/availableListing"
                  element={<AvailableListingIndex />}
                />
                <Route
                  path="/availableListing/listingDetails/*"
                  element={<ListingDetailsWrapper />}
                />

                <Route path="*" element={<Navigate to="/myGarage" />} />
              </Route>
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/auth" element={<AuthRoutes />}>
                <Route path="login" element={<Login />} />
              </Route>

              <Route path="*" element={<Navigate to="/auth/login" />} />
              <Route path="" element={<Navigate to="/auth/login" />} />
            </Routes>
          </>
        )}
      </React.Suspense>
    </>
  );
}