import React from "react";
import AuthContext from "context/autho";
import jwt_decode from "jwt-decode";

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !!localStorage.getItem("access_token")
  );
  
  const decodeToken = () => {
    let token = localStorage.getItem("access_token");
    if (token) {
      let decodeToken = jwt_decode(localStorage.getItem("access_token"));
      if (Date.now() >= decodeToken.exp * 1000) {
        localStorage.removeItem("access_token");
        setIsAuthenticated(false);
        return null;
      } else {
        return decodeToken;
      }
    } else {
      if (isAuthenticated) {
        setIsAuthenticated(false);
      }
      return null;
    }
  };

  const [user, setUser] = React.useState(decodeToken());

  return (
    <>
      <AuthContext.Provider
        value={{ isAuthenticated, setIsAuthenticated, user, setUser }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
}
